import Axios from 'axios';

import { AuthorizeResponse, RefreshSessionResponse } from '@/interfaces/auth';
import { Country } from '@/interfaces/country';
import { CreateDomainRequest } from '@/interfaces/createDomainRequest';
import { CreateDomainResponse } from '@/interfaces/createDomainResponse';
import { CreateFarmRequest } from '@/interfaces/createFarmRequest';
import { CreateFarmResponse } from '@/interfaces/createFarmResponse';
import { CreateParcelRequest } from '@/interfaces/createParcelRequest';
import { CreateParcelResponse } from '@/interfaces/createParcelResponse';
import { CropType } from '@/interfaces/cropType';
import { Unit } from '@/interfaces/unit';
import { Farm } from '@/interfaces/farm';
import { Parcel } from '@/interfaces/parcel';
import { ParcelByBbox } from '@/interfaces/parcelByBbox';
import { UpdateParcelResponse } from '@/interfaces/updateParcelResponse';
import { UploadRequest } from '@/interfaces/uploadRequest';
import { Organization } from '@/interfaces/organization';
import { FeatureCollection } from '@turf/helpers';

class API {
  static _URL = process.env.VUE_APP_PRODUCT_MANAGER + '/api';

  static getCropTypes(): Promise<CropType[]> {
    return Axios.get(`${API._URL}/crop-types`, { withCredentials: true }).then((result) => result.data);
  }

  static getCountries(): Promise<Country[]> {
    return Axios.get(`${API._URL}/countries?valid=true`, { withCredentials: true }).then((result) => result.data);
  }

  static getOrganizations(countryId: string): Promise<Organization[]> {
    return Axios.get(`${API._URL}/organizations?country=${countryId}`, { withCredentials: true }).then(
      (result) => result.data
    );
  }

  static getUnits(orgId: string): Promise<Unit[]> {
    return Axios.get(`${API._URL}/units?org=${orgId}`, { withCredentials: true }).then((result) => result.data);
  }

  static getFarms(unitId: string): Promise<Farm[]> {
    return Axios.get(`${API._URL}/farms?unit=${unitId}&include_shapes=true`, { withCredentials: true }).then(
      (result) => result.data
    );
  }
  static getFarm(farmId: string): Promise<Farm> {
    return Axios.get(`${API._URL}/farm?id=${farmId}`, { withCredentials: true }).then((result) => result.data);
  }

  static getParcels(farmId: string): Promise<Parcel[]> {
    return Axios.get(`${API._URL}/parcels?farm=${farmId}`, { withCredentials: true }).then((result) => result.data);
  }

  static getCarShapes(unitId: string): Promise<{ UnitID: string; Shape: FeatureCollection }> {
    return Axios.get(`${API._URL}/car-shapes?unit=${unitId}`, { withCredentials: true }).then((result) => result.data);
  }

  static uploadCarShapes(unitId: string, shape: FeatureCollection): Promise<void> {
    return Axios.post(`${API._URL}/car-shapes?unit=${unitId}`, shape, {
      withCredentials: true
    }).then((result) => result.data);
  }

  static getParcelsByBBox(
    llLong: number,
    llLat: number,
    urLong: number,
    urLat: number,
    date: string,
    orgs: string[]
  ): Promise<ParcelByBbox[]> {
    return Axios.get(`${API._URL}/parcel-by-bbox`, {
      params: {
        ll_long: llLong,
        ll_lat: llLat,
        ur_long: urLong,
        ur_lat: urLat,
        date: date,
        surveys: true,
        orgs: orgs.join(',')
      },
      withCredentials: true
    }).then((result) => result.data);
  }

  static createUnit(payload: CreateDomainRequest): Promise<CreateDomainResponse> {
    return Axios.post(`${API._URL}/unit`, payload, { withCredentials: true }).then((result) => result.data);
  }

  static createFarm(payload: CreateFarmRequest): Promise<CreateFarmResponse> {
    return Axios.post(`${API._URL}/farm`, payload, { withCredentials: true }).then((result) => result.data);
  }

  static createParcel(payload: CreateParcelRequest): Promise<CreateParcelResponse> {
    return Axios.post(`${API._URL}/parcel`, payload, { withCredentials: true }).then((result) => result.data);
  }

  static updateParcel(payload: Partial<Parcel>): Promise<UpdateParcelResponse> {
    return Axios.patch(`${API._URL}/parcel`, payload, { withCredentials: true }).then((result) => result.data);
  }
  static authorize(role: string): Promise<AuthorizeResponse> {
    return Axios.get(`${API._URL}/authorize?role=${role}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(() => false);
  }

  static upload(payload: UploadRequest, season: string): Promise<void> {
    return Axios.post(`${API._URL}/batch-upload?season=${season}`, payload, { withCredentials: true }).then(
      (result) => result.data
    );
  }

  static transform(json: any): Promise<any> {
    return Axios.post(`${API._URL}/transform`, json, { withCredentials: true }).then((result) => result.data);
  }

  static refreshSession(): Promise<RefreshSessionResponse> {
    return Axios.get(`${API._URL}/refresh-session`, { withCredentials: true }).then((result) => result.data);
  }
}

export default API;
