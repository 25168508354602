
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Unit } from '@/interfaces/unit';

@Component
export default class SelectSeason extends Vue {
  @Prop() unit: Unit;
  @Prop() season: string;

  public selectedValue: string = null;

  private mounted(): void {
    this.selectedValue = this.season;
  }

  public filterOption(input: string, option: any) {
    return option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0;
  }

  get seasons(): string[] {
    return this.unit?.Seasons || [];
  }

  public onChange(): void {
    this.$emit('on-change', this.isValid ? this.selectedValue : null);
  }

  private get isValid(): boolean {
    return this.selectedValue ? /^\d{4}/g.test(this.selectedValue) : true;
  }
}
