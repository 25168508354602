
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class CreateEntityModal extends Vue {
  @Prop() title: string;
  @Prop() visible: boolean;
  @Prop() preFill: string;
  @Prop() preFillCode: string;
  @Prop() hasCode: boolean;
  name = '';
  code = '';

  @Watch('preFill', { immediate: true })
  private onPrefillChanged(): void {
    this.name = this.preFill ?? '';
  }

  @Watch('preFillCode', { immediate: true })
  private onPrefillCodeChanged(): void {
    this.code = this.preFillCode ?? '';
  }

  ok(): void {
    this.$emit('ok', this.name || this.preFill, this.code || this.preFillCode);
    this.name = null;
    this.code = null;
  }

  cancel(): void {
    this.$emit('cancel');
    this.name = null;
    this.code = null;
  }
}
