
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SeasonValidationOutput } from '@/interfaces/seasonValidation';
import * as FileSaver from 'file-saver';

@Component({
  components: {}
})
export default class SeasonValidationResultsModal extends Vue {
  @Prop() visible: boolean;
  @Prop() seasonValidationResult: SeasonValidationOutput;

  save(): void {
    FileSaver.saveAs(new Blob([JSON.stringify(this.seasonValidationResult, null, 4)]), 'validations.json');
  }

  uploadAll(): void {
    this.$emit('upload-all');
  }

  uploadOnlyValid(): void {
    this.$emit('upload-only-valid');
  }

  cancel(): void {
    this.$emit('cancel');
  }
}
