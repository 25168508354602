
import Mapbox from 'mapbox-gl-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { featureCollection, FeatureCollection, Polygon } from '@turf/helpers';
import bbox from '@turf/bbox';
import { FarmOverlapMapping } from '@/interfaces/farmOverlapMapping';
import { ParcelMapping } from '@/interfaces/parcelMapping';
import * as FileSaver from 'file-saver';
import { Map } from 'mapbox-gl';

@Component({
  components: {
    Mapbox
  }
})
export default class ManageFarmOverlappingModal extends Vue {
  @Prop() visible: boolean;
  @Prop() farmOverlapMapping: FarmOverlapMapping[];
  @Prop() shapeParcelNames: ParcelMapping[];
  private map: Map;
  private overlapParcelSourceId = 'overlapParcelSourceId';
  private overlapParcelLayerId = 'overlapParcelLayerId';
  private farmSourceId = 'farmSourceId';
  private farmLayerId = 'farmLayerId';
  public selectedMappingId: string = null;
  public selectedMapping: FarmOverlapMapping = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  filterOption(input: string, option: any): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  getItemName(mapping: FarmOverlapMapping): string {
    return `${mapping.parcelName} for ${mapping.parcelFarmName} ${mapping.parcelFarmCode || ''} overlap ${
      mapping.farmName
    } ${mapping.farmCode || ''} - ${mapping.percent}%`;
  }

  get partialOverlap(): FarmOverlapMapping[] {
    return this.farmOverlapMapping.filter((mapping: FarmOverlapMapping) => mapping.percent <= 100);
  }

  get fullOverlapMessages(): Set<string> {
    const messages = new Set<string>();
    this.farmOverlapMapping.forEach((mapping: FarmOverlapMapping) => {
      if (mapping.percent === 100) {
        messages.add(
          `The farm named "${mapping.parcelFarmName} ${mapping.parcelFarmCode || ''}" overlaps with the farm "${
            mapping.farmName
          } ${mapping.farmCode || ''}" exists in our Database. Please select this name in point 7.`
        );
      }
    });
    return messages;
  }

  get partialOverlapMessage(): string {
    return `Please compare these problematic parcels (${this.partialOverlap
      .map((m) => m.parcelName)
      .join(', ')}) with the shape download from the Gamaya Database to correct, before retrying.`;
  }

  handleChange(mappingId: string): void {
    this.selectedMappingId = mappingId;
    this.cleanupLayer(this.overlapParcelLayerId, this.overlapParcelSourceId);
    this.cleanupLayer(this.farmLayerId, this.farmSourceId);

    this.selectedMapping = this.farmOverlapMapping.find((m: FarmOverlapMapping) => m.uniqueId === mappingId);
    this.map.addSource(this.overlapParcelSourceId, {
      type: 'geojson',
      data: this.selectedMapping.parcelShape as FeatureCollection<Polygon>
    });
    this.map.addLayer({
      type: 'fill',
      id: this.overlapParcelLayerId,
      source: this.overlapParcelSourceId,
      paint: { 'fill-opacity': 0.5, 'fill-color': 'rgba(200, 100, 240, 1)' }
    });

    this.map.addSource(this.farmSourceId, {
      type: 'geojson',
      data: this.selectedMapping.farmShape as FeatureCollection<Polygon>
    });
    this.map.addLayer({
      type: 'line',
      id: this.farmLayerId,
      source: this.farmSourceId,
      paint: { 'line-width': 2, 'line-color': 'darkgray' }
    });

    const geoBbox = bbox(this.selectedMapping.parcelShape);
    this.map.fitBounds(
      [
        [geoBbox[0], geoBbox[3]],
        [geoBbox[2], geoBbox[1]]
      ],
      { duration: 0 }
    );
  }

  private cleanupLayer(layerId: string, sourceId: string): void {
    if (this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
    if (this.map.getSource(sourceId)) {
      this.map.removeSource(sourceId);
    }
  }

  onMapLoaded(map: Map): void {
    this.map = map;
    this.selectNextParcel();
  }

  download(): void {
    FileSaver.saveAs(
      new Blob([
        JSON.stringify(
          featureCollection(
            this.partialOverlap.map((o) => {
              const feature = Object.assign({}, o.parcelShape.features[0]);
              feature.properties.name = o.parcelName;
              return feature;
            })
          )
        )
      ]),
      'farm_overlap.geojson'
    );
  }

  ignoreAll(): void {
    this.partialOverlap.forEach((m: FarmOverlapMapping) => {
      const parcelMapping = this.shapeParcelNames.find((p: ParcelMapping) => p.dbId === m.parcelId);
      if (parcelMapping) {
        parcelMapping.skipUpload = true;
      }
    });
    this.cancel();
  }

  skipOverlapping(): void {
    this.clear();
    this.$emit('proceed');
  }

  private selectNextParcel(): void {
    if (this.farmOverlapMapping.length) {
      this.handleChange(this.farmOverlapMapping[0].uniqueId);
    } else {
      this.cancel();
    }
  }

  private clear(): void {
    this.selectedMappingId = null;
    this.selectedMapping = null;
    this.cleanupLayer(this.farmLayerId, this.farmSourceId);
    this.cleanupLayer(this.overlapParcelLayerId, this.overlapParcelSourceId);
  }

  cancel(): void {
    this.clear();
    this.$emit('cancel');
  }
}
