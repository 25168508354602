
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CustomFieldMapping } from '@/interfaces/customFieldMapping';

@Component
export default class CustomFieldsMapping extends Vue {
  @Prop() parcelFields: string[];
  @Prop() shapeFields: string[];

  public fields: CustomFieldMapping[] = [];
  private uniqueIdCounter = 0;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  public filterOption(input: string, option: any): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  private get notAddedParcelFields(): string[] {
    return this.parcelFields.filter(
      (name) => this.fields.find(({ parcelField }) => parcelField === name) === undefined
    );
  }

  public handleShapeFieldChange(editedField: CustomFieldMapping, name: string): void {
    this.fields = this.fields.map((field: CustomFieldMapping) => {
      return {
        ...field,
        shapeField: field.id === editedField.id ? name : field.shapeField
      };
    });
  }

  public handleParcelFieldChange(editedField: CustomFieldMapping, name: string): void {
    this.fields = this.fields.map((field: CustomFieldMapping) => {
      return {
        ...field,
        parcelField: field.id === editedField.id ? name : field.parcelField
      };
    });
  }

  public onRemove(editedField: CustomFieldMapping): void {
    this.fields = this.fields.filter((field: CustomFieldMapping) => field.id !== editedField.id);
  }

  public onAdd(): void {
    if (this.notAddedParcelFields.length) {
      this.fields.push({
        id: this.uniqueIdCounter,
        shapeField: null,
        parcelField: this.notAddedParcelFields[0]
      });
      this.uniqueIdCounter += 1;
    }
  }

  @Watch('fields')
  private onFieldsChanged(): void {
    this.$emit(
      'onUpdate',
      this.fields.filter((field) => !!field.shapeField)
    );
  }
}
