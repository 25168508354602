
import Mapbox from 'mapbox-gl-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FeatureCollection, Polygon, Feature, featureCollection, Id, BBox } from '@turf/helpers';
import bbox from '@turf/bbox';
import buffer from '@turf/buffer';
import bboxPolygon from '@turf/bbox-polygon';
import moment from 'moment';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import { drawStyles } from '@/components/draw-styles';
import { featureEach } from '@turf/meta';
import simplify from '@turf/simplify';
import cleanCoords from '@turf/clean-coords';
import { FarmMapping } from '@/interfaces/farmMapping';
import API from '@/services/api';
import { Parcel } from '@/interfaces/parcel';
import { OverlapMapping } from '@/interfaces/overlapMapping';
import { ShapeEditingResult } from '@/interfaces/shapeEditingResult';
import { GeoJSONSource, Layer, Map, Popup } from 'mapbox-gl';
import cloneDeep from 'lodash.clonedeep';
import { ParcelByBbox } from '@/interfaces/parcelByBbox';
import { Modal } from 'ant-design-vue';
import { Survey } from '@/interfaces/survey';

const overlapWorker = new Worker(new URL('../services/overlap.worker.ts', import.meta.url));

interface HistoryRecord {
  polygons: FeatureCollection<Polygon>;
  result: ShapeEditingResult;
}

@Component({
  components: {
    Mapbox
  }
})
export default class ShapeEditingModal extends Vue {
  @Prop() visible: boolean;
  @Prop() shape: FeatureCollection;
  @Prop() shapeFarmNames: FarmMapping[];
  @Prop() manualFarmField: string;
  @Prop() selectedFarmField: string;
  @Prop() selectedFarmCodeField: string;
  @Prop() parseCreationDate: (properties: any) => string;
  @Prop() selectedEntityId: string;
  @Prop() selectedSeason: string;

  highlightAllOverlappings = true;
  autoResolveFullOverlappings = true;
  autoApproveFarm = false;
  deleteExisting = false;
  backgroundLayer = {
    SATELLITE: 'Satellite',
    SENTINEL: 'Sentinel',
    MAXAR: 'Maxar',
    GOOGLE_SATELLITE: 'Google_Satellite'
  };

  selectedBackground: string = this.backgroundLayer.SATELLITE;

  selectedShapeFarmNameWithCode: string = null;
  private map: Map;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private draw: any;
  drawHistory: HistoryRecord[] = [];
  drawSelectedFeatureIds: string[] = null;
  drawSelectedFileParcel: Feature = null;
  drawSelectedFileOverlap: OverlapMapping = null;
  private replaceModeFeature: Feature<Polygon> = null;
  private currentProcessedId: Id = null;
  private parcelsWithSurveysLayerId = 'parcelsWithSurveysLayerId';
  private parcelsFromOtherFarmsLayerId = 'parcelsFromOtherFarmsLayerId';
  private overlapsLayerId = 'overlapsLayerId';
  private overlap: OverlapMapping[] = [];
  private dbParcels: ParcelByBbox[] = [];
  private featuresFromOtherFarms: Feature[] = [];
  private basemapSourceId = 'basemap-source';
  private basemapLayerId = 'basemap-layer';

  private result: ShapeEditingResult = {
    deletedDbParcels: {},
    editedDbParcels: {},
    mapping: []
  };

  private editedFeaturesByFarmCodeName: {
    [key: string]: {
      file: Feature[];
      db: Feature[];
      dbParcels: ParcelByBbox[];
    };
  } = {};

  public get isApproveDisabled(): boolean {
    return !this.deleteExisting && (this.overlap.length > 0 || this.selectedShapeFarmNameWithCode === null);
  }

  changeBackground(layer: string): void {
    this.selectedBackground = layer;

    if (this.map.getLayer(this.basemapLayerId)) {
      this.map.removeLayer(this.basemapLayerId);
    }
    if (this.map.getSource(this.basemapSourceId)) {
      this.map.removeSource(this.basemapSourceId);
    }

    if (layer === this.backgroundLayer.SENTINEL) {
      this.showSatelliteLayer();
    }
    if (layer === this.backgroundLayer.MAXAR) {
      this.showMaxarLayer();
    }
    if (layer === this.backgroundLayer.GOOGLE_SATELLITE) {
      this.showGoogleSatelliteLayer();
    }
  }

  private showSatelliteLayer() {
    const time = moment.utc().format('YYYY-MM-DD');
    this.map.addSource(this.basemapSourceId, {
      type: 'raster',
      tiles: [
        `https://services.sentinel-hub.com/ogc/wms/${process.env.VUE_APP_SENTINEL_TOKEN_SHAPE_UPLOADER}?showLogo=false&service=WMS&request=GetMap&layers=TRUE_COLOR,DATE&styles=&format=image%2Fjpeg&transparent=false&version=1.1.1&maxcc=7&time=${time}&height=512&width=512&srs=EPSG%3A3857&bbox={bbox-epsg-3857}`
      ]
    });
    this.map.addLayer({
      id: this.basemapLayerId,
      type: 'raster',
      source: this.basemapSourceId
    });
    this.reOrderLayers();
  }

  private showMaxarLayer() {
    const connectId = '461ff06a-f98a-4c06-8a0c-19aecb49a2b1';
    this.map.addSource(this.basemapSourceId, {
      type: 'raster',
      tiles: [
        `https://securewatch.digitalglobe.com/earthservice/wmtsaccess?connectId=${connectId}&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&TileMatrixSet=EPSG:3857&LAYER=DigitalGlobe:ImageryTileService&FORMAT=image/jpeg&STYLE=&featureProfile=Global_Currency_Profile&TileMatrix=EPSG:3857:{z}&TILEROW={y}&TILECOL={x}`
      ]
    });
    this.map.addLayer({
      id: this.basemapLayerId,
      type: 'raster',
      source: this.basemapSourceId
    });
    this.reOrderLayers();
  }

  private showGoogleSatelliteLayer() {
    this.map.addSource(this.basemapSourceId, {
      type: 'raster',
      tiles: ['https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}']
    });
    this.map.addLayer({
      id: this.basemapLayerId,
      type: 'raster',
      source: this.basemapSourceId
    });
    this.reOrderLayers();
  }

  private reOrderLayers(): void {
    const drawLayerIds = [
      'gl-draw-polygon-fill-inactive',
      'gl-draw-polygon-fill-active',
      'gl-draw-polygon-fill-file',
      'gl-draw-polygon-stroke-file',
      'gl-draw-polygon-midpoint',
      'gl-draw-polygon-stroke-inactive',
      'gl-draw-point-active',
      'gl-draw-polygon-stroke-active',
      'gl-draw-polygon-fill-static',
      'gl-draw-polygon-stroke-static',
      'gl-draw-polygon-and-line-vertex-inactive',
      'gl-draw-polygon-and-line-vertex-stroke-inactive',
      'gl-draw-point-point-stroke-inactive'
    ];
    const drawLayers: Layer[] = [];
    drawLayerIds.forEach((id) => {
      drawLayers.push(this.map.getLayer(`${id}.cold`));
      drawLayers.push(this.map.getLayer(`${id}.hot`));
    });

    const parcelsWithSurveysLayerId = this.map.getLayer(this.parcelsWithSurveysLayerId);
    const parcelsFromOtherFarmsLayerId = this.map.getLayer(this.parcelsFromOtherFarmsLayerId);
    const overlapsLayerId = this.map.getLayer(this.overlapsLayerId);
    const backgroundLayer = this.map.getLayer(this.basemapLayerId);

    // layers ordering from top to bottom
    const layersOrderingList = [
      ...drawLayers,
      overlapsLayerId,
      parcelsWithSurveysLayerId,
      parcelsFromOtherFarmsLayerId,
      backgroundLayer
    ];

    const availableLayers = layersOrderingList.filter((layer) => !!layer);
    if (availableLayers.length > 1) {
      for (let i = availableLayers.length - 1; i > -1; i--) {
        this.map.moveLayer(availableLayers[i].id);
      }
    }
  }

  get entitiesToUploadToServer(): any {
    return {
      edited: Object.values(this.result.editedDbParcels).filter(({ feature }) => !feature.properties.invalid).length,
      deleted: Object.values(this.result.deletedDbParcels).length
    };
  }

  upload(): void {
    this.draw.changeMode('simple_select');
    this.clearHistory();
    this.saveToHistory();

    const deletedDbParcelIds = Object.keys(this.result.deletedDbParcels);
    if (deletedDbParcelIds.length) {
      this.dbParcels = this.dbParcels.filter((dbParcel: ParcelByBbox) => !deletedDbParcelIds.includes(dbParcel.id));
    }

    this.$emit('upload', this.result);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  filterOption(input: string, option: any): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  getFileFeatures(farmNameWithCode: string): Feature[] {
    if (this.editedFeaturesByFarmCodeName[farmNameWithCode]) {
      return this.editedFeaturesByFarmCodeName[farmNameWithCode].file;
    }
    let fileParcels: Feature[] = [];
    const { farmName, code } = this.getSelectedFarmNameAndCode();
    this.shape.features.forEach((feature: Feature) => {
      const fileFarmName = this.manualFarmField || feature.properties[this.selectedFarmField] || '';
      const fileFarmCode = feature.properties[this.selectedFarmCodeField] || '';
      if (farmName === fileFarmName.toString() && (fileFarmCode ? code === fileFarmCode.toString() : true)) {
        feature.properties.newGeoJson = true;
        fileParcels.push(feature);
      }
    });
    return fileParcels;
  }

  private getSelectedFarmNameAndCode() {
    const parts = this.selectedShapeFarmNameWithCode.split('|');
    return { farmName: parts[0], code: parts[1] };
  }

  getDbData(farmNameWithCode: string, resultBbox: BBox): Promise<[ParcelByBbox[], Feature[]]> {
    if (this.editedFeaturesByFarmCodeName[farmNameWithCode]) {
      return Promise.resolve([
        this.editedFeaturesByFarmCodeName[farmNameWithCode].dbParcels,
        this.editedFeaturesByFarmCodeName[farmNameWithCode].db
      ]);
    }
    if (!resultBbox) {
      return Promise.resolve([[], []]);
    }
    return API.getParcelsByBBox(
      resultBbox[0],
      resultBbox[1],
      resultBbox[2],
      resultBbox[3],
      moment.utc().format('YYYY-MM-DD'),
      [this.selectedEntityId]
    ).then((dbParcels: ParcelByBbox[]) => {
      let parcels = dbParcels;
      if (this.selectedSeason) {
        parcels = dbParcels.filter((parcel: ParcelByBbox) => !parcel.Seasons || !!parcel.Seasons[this.selectedSeason]);
      }

      const dbFeatures: Feature[] = [];
      parcels.forEach((dbParcel: ParcelByBbox) => {
        if (!dbParcel.Surveys || dbParcel.Surveys.length === 0) {
          const feature = dbParcel.Shape.features[0];
          feature.properties.parcelId = dbParcel.id;
          dbFeatures.push(feature);
        }
      });
      return [parcels, dbFeatures];
    });
  }

  bboxIntersect(bbox1: BBox, bbox2: BBox) {
    return !(bbox1[0] > bbox2[2] || bbox1[2] < bbox2[0] || bbox1[3] < bbox2[1] || bbox1[1] > bbox2[3]);
  }

  getFeaturesInBboxExceptCurrentFarm(bboxAll: BBox) {
    let allFeatures: Feature[] = [];

    const savedFarms = Object.keys(this.editedFeaturesByFarmCodeName);
    savedFarms.forEach((farmNameWithCode) => {
      if (farmNameWithCode !== this.selectedShapeFarmNameWithCode) {
        allFeatures = allFeatures.concat(this.editedFeaturesByFarmCodeName[farmNameWithCode].file);
      }
    });
    this.shape.features.forEach((feature: Feature) => {
      const fileFarmName = this.manualFarmField || feature.properties[this.selectedFarmField] || '';
      const fileFarmCode = feature.properties[this.selectedFarmCodeField] || '';
      const farmNameWithCode = fileFarmName + '|' + fileFarmCode;
      if (!savedFarms.includes(farmNameWithCode) && farmNameWithCode !== this.selectedShapeFarmNameWithCode) {
        allFeatures.push(feature);
      }
    });

    return allFeatures.filter((feature: Feature) => this.bboxIntersect(bbox(feature), bboxAll));
  }

  private getDbFeaturesWithSurveys(): Feature[] {
    const dbFeaturesWithSurveys: Feature[] = [];
    this.dbParcels.forEach((dbParcel: ParcelByBbox) => {
      if (!this.result.deletedDbParcels[dbParcel.id] && dbParcel.Surveys && dbParcel.Surveys.length) {
        dbFeaturesWithSurveys.push(dbParcel.Shape.features[0]);
      }
    });
    return dbFeaturesWithSurveys;
  }

  private updateDbFeaturesWithSurveys(dbFeaturesWithSurveys: Feature[]): void {
    if (dbFeaturesWithSurveys.length) {
      const source = this.map.getSource(this.parcelsWithSurveysLayerId) as GeoJSONSource;
      if (source) {
        source.setData(featureCollection(dbFeaturesWithSurveys) as any);
      }
    }
  }

  onHandleShapeFarmNameChange(farmNameWithCode: string): void {
    this.handleShapeFarmNameChange(farmNameWithCode, false);
  }

  handleShapeFarmNameChange(farmNameWithCode: string, clearResults = false): void {
    this.$store.dispatch('showGlobalLoader', 'Loading data for selected farm...');

    this.saveFarmState(clearResults);
    this.selectedShapeFarmNameWithCode = farmNameWithCode;
    this.clearHistory();
    this.draw.deleteAll();
    if (this.map.getLayer(this.parcelsWithSurveysLayerId)) {
      this.map.removeLayer(this.parcelsWithSurveysLayerId);
      this.map.removeSource(this.parcelsWithSurveysLayerId);
    }
    if (this.map.getLayer(this.parcelsFromOtherFarmsLayerId)) {
      this.map.removeLayer(this.parcelsFromOtherFarmsLayerId);
      this.map.removeSource(this.parcelsFromOtherFarmsLayerId);
    }

    const fileFeatures = this.getFileFeatures(farmNameWithCode);
    const shapesCollection = featureCollection(fileFeatures);
    const shapesBbox = fileFeatures.length ? bbox(shapesCollection) : null;
    const resultBbox = shapesBbox ? bbox(buffer(bboxPolygon(shapesBbox), 1)) : null;

    this.getDbData(farmNameWithCode, resultBbox).then(([dbParcels, dbFeatures]) => {
      this.dbParcels = dbParcels;

      const dbFeaturesWithSurveys: Feature[] = this.getDbFeaturesWithSurveys();

      if (dbFeaturesWithSurveys.length) {
        this.map.addLayer({
          id: this.parcelsWithSurveysLayerId,
          type: 'line',
          source: {
            type: 'geojson',
            data: featureCollection(dbFeaturesWithSurveys) as any
          },
          paint: {
            'line-color': 'blue',
            'line-width': 2,
            'line-dasharray': [2, 1]
          }
        });
      }

      this.featuresFromOtherFarms = resultBbox ? this.getFeaturesInBboxExceptCurrentFarm(resultBbox) : [];

      if (this.featuresFromOtherFarms.length) {
        this.map.addLayer({
          id: this.parcelsFromOtherFarmsLayerId,
          type: 'fill',
          source: {
            type: 'geojson',
            data: featureCollection(this.featuresFromOtherFarms) as any
          },
          paint: { 'fill-opacity': 0.3, 'fill-color': '#F5CB06' }
        });
      }

      this.draw.add(featureCollection(dbFeatures));
      this.draw.add(shapesCollection);

      this.$store.dispatch('hideGlobalLoader');

      const featuresToZoom = [...fileFeatures, ...dbFeatures, ...dbFeaturesWithSurveys];
      if (featuresToZoom.length) {
        this.zoomToGeoJson(featureCollection(featuresToZoom));
      }

      this.checkOverlapping().then(() => {
        if (this.autoResolveFullOverlappings) {
          const fileFeaturesToRemove: Feature[] = [];
          this.overlap.forEach((overlap: OverlapMapping) => {
            if (overlap.percent === 100 && overlap.hasEqualAreas) {
              fileFeaturesToRemove.push(overlap.feature);
            }
          });
          if (fileFeaturesToRemove.length) {
            this.draw.delete(fileFeaturesToRemove.map((feature: Feature) => feature.id));
            this.deleteFeatures(fileFeaturesToRemove);
          }
        }
        this.saveToHistory();
      });
    });
  }

  private updateDrawSelectedFileOverlaps(): void {
    let drawSelectedFileOverlap: OverlapMapping = null;
    if (this.drawSelectedFileParcel) {
      const drawSelectedFileOverlaps = this.overlap.filter((over: OverlapMapping) => {
        return over.feature.id === this.drawSelectedFileParcel.id && !!over.dbId;
      });
      drawSelectedFileOverlap = drawSelectedFileOverlaps.length ? drawSelectedFileOverlaps[0] : null;
    }
    this.drawSelectedFileOverlap = drawSelectedFileOverlap;
  }

  onChangeOverlappingMode(): void {
    this.checkOverlapping();
  }

  private isAllowToChangeAutoResolve(): Promise<void> {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: 'Are you sure?',
        content: 'All changes for current farm will be lost',
        onOk() {
          resolve();
        },
        onCancel() {
          reject();
        }
      });
    });
  }

  onChangeAutoResolveOverlapping(): void {
    this.isAllowToChangeAutoResolve()
      .then(() => {
        this.handleShapeFarmNameChange(this.selectedShapeFarmNameWithCode, true);
      })
      .catch(() => {
        this.autoResolveFullOverlappings = !this.autoResolveFullOverlappings;
      });
  }

  private checkOverlapping(): Promise<void> {
    if (this.map.getLayer(this.overlapsLayerId)) {
      this.map.removeLayer(this.overlapsLayerId);
      this.map.removeSource(this.overlapsLayerId);
    }

    const fileFeatures: Feature[] = [];
    const dbParcels: Parcel[] = this.dbParcels.filter(
      (parcel: Parcel) => parcel.Surveys && parcel.Surveys.length && !this.result.deletedDbParcels[parcel.id]
    );
    const allFeatures = this.draw.getAll().features;
    allFeatures.forEach((feature: Feature) => {
      if (feature.properties && feature.properties.parcelId) {
        const dbParcel = this.dbParcels.find((parcel: Parcel) => parcel.id === feature.properties.parcelId);
        if (dbParcel) {
          dbParcels.push({
            id: dbParcel.id,
            Name: dbParcel.Name,
            Shape: {
              features: [feature]
            },
            Surveys: dbParcel.Surveys
          } as Parcel);
        }
      } else {
        fileFeatures.push(feature);
      }
    });

    return this.calculateOverlapping(dbParcels, fileFeatures, this.featuresFromOtherFarms).then(
      (overlap: OverlapMapping[]) => {
        this.overlap = overlap;
        this.updateValidationEditedDbParcels(dbParcels);
        this.updateDrawSelectedFileOverlaps();

        const overlapFeatures: Feature[] = [];
        overlap.forEach((over: OverlapMapping) => {
          const buffered = buffer(over.intersect, 5, { units: 'meters' });
          buffered.properties.percent = over.percent;
          buffered.properties.hasEqualAreas = over.hasEqualAreas;
          overlapFeatures.push(buffered);
        });
        this.map.addLayer({
          id: this.overlapsLayerId,
          type: 'fill',
          source: {
            type: 'geojson',
            data: featureCollection(overlapFeatures) as any
          },
          paint: { 'fill-color': 'rgba(255, 0, 0, 0.5)', 'fill-outline-color': 'rgb(255, 0, 0)' }
        });

        if (this.overlap.length > 0) {
          this.autoApproveFarm = false;
        }

        if (this.autoApproveFarm && !this.isApproveDisabled) {
          this.approve();
        }

        return null;
      }
    );
  }

  private updateValidationEditedDbParcels(dbParcels: Parcel[]): void {
    const result = cloneDeep(this.result);
    let needUpdate = false;
    dbParcels.forEach((parcel: Parcel) => {
      const edited = result.editedDbParcels[parcel.id];
      if (edited) {
        const hasOverlap = this.overlap.some((overlap: OverlapMapping) => {
          return (
            overlap.feature.properties.parcelId === parcel.id || overlap.feature2.properties.parcelId === parcel.id
          );
        });
        if (hasOverlap && edited.feature.properties.invalid === undefined) {
          edited.feature.properties.invalid = true;
          needUpdate = true;
        }
        if (!hasOverlap && edited.feature.properties.invalid === true) {
          delete edited.feature.properties.invalid;
          needUpdate = true;
        }
      }
    });
    if (needUpdate) {
      this.result = result;
    }
  }

  private calculateOverlapping(
    parcels: Parcel[],
    features: Feature[],
    differentFarmFeatures: Feature[]
  ): Promise<OverlapMapping[]> {
    return new Promise<OverlapMapping[]>((resolve) => {
      this.$store.dispatch('showGlobalLoader', 'Checking overlappings...');

      const onMessage = (result: MessageEvent) => {
        if (result.data.progress) {
          this.$store.dispatch('setGlobalLoaderMessage', result.data.progress);
          return;
        }
        resolve(result.data as OverlapMapping[]);
        this.$store.dispatch('hideGlobalLoader');
        overlapWorker.removeEventListener('message', onMessage);
      };

      overlapWorker.addEventListener('message', onMessage);
      overlapWorker.postMessage({
        stopOnFirst: !this.highlightAllOverlappings,
        parcels,
        features,
        differentFarmFeatures
      });
    });
  }

  private deleteFromFile(): void {
    this.draw.delete([this.drawSelectedFileOverlap.feature.id]);
    this.deleteFeatures([this.drawSelectedFileOverlap.feature]).then(() => {
      this.goNext();
    });
  }

  private deleteFromDB(): void {
    const parcelFeature = this.draw
      .getAll()
      .features.find((feature: Feature) => feature.properties.parcelId === this.drawSelectedFileOverlap.dbId);
    if (parcelFeature) {
      this.draw.delete([parcelFeature.id]);
      this.deleteFeatures(
        [parcelFeature],
        this.parseCreationDate(this.drawSelectedFileOverlap.feature.properties)
      ).then(() => {
        this.goNext();
      });
    }
  }

  private deleteWithSurveys(): void {
    const dbParcel = this.dbParcels.find((parcel: Parcel) => parcel.id === this.drawSelectedFileOverlap.dbId);

    let lastSurveyDate: moment.Moment = null;
    dbParcel.Surveys.forEach((survey: Survey) => {
      const momentSurveyDate = moment.utc(survey.Date, 'YYYY-MM-DD');
      if (!lastSurveyDate || momentSurveyDate.isAfter(lastSurveyDate)) {
        lastSurveyDate = momentSurveyDate;
      }
    });

    const result = cloneDeep(this.result);
    result.deletedDbParcels[dbParcel.id] = {
      parcel: dbParcel,
      deletedDate: lastSurveyDate.add(1, 'days').toISOString()
    };
    this.result = result;

    const dbFeaturesWithSurveys: Feature[] = this.getDbFeaturesWithSurveys();
    this.updateDbFeaturesWithSurveys(dbFeaturesWithSurveys);
    this.checkOverlapping().then(() => {
      this.goNext();
    });
  }

  private overwrite(): void {
    const parcelFeature = this.draw
      .getAll()
      .features.find((feature: Feature) => feature.properties.parcelId === this.drawSelectedFileOverlap.dbId);
    const dbParcel = this.dbParcels.find((parcel: Parcel) => parcel.id === this.drawSelectedFileOverlap.dbId);
    if (parcelFeature && dbParcel) {
      this.draw.delete([parcelFeature.id]);
      this.result.mapping.push({
        feature: this.drawSelectedFileOverlap.feature,
        parcel: dbParcel
      });
      this.checkOverlapping().then(() => {
        this.goNext();
      });
    }
  }

  private createDrawLayer(): void {
    this.draw = new MapboxDraw({
      userProperties: true,
      controls: {
        trash: true
      },
      displayControlsDefault: false,
      styles: drawStyles
    });
    this.map.addControl(this.draw);
  }

  destroyed(): void {
    document.onkeyup = null;
  }

  onMapLoaded(map: Map): void {
    this.map = map;
    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disable();
    this.map.on('draw.selectionchange', this.drawSelectionChange.bind(this));
    this.map.on('draw.create', this.drawCreate.bind(this));
    this.map.on('draw.delete', this.drawDelete.bind(this));
    this.map.on('draw.update', this.drawUpdate.bind(this));
    this.map.on('draw.modechange', this.drawModeChange.bind(this));

    const popup = new Popup({
      closeButton: false,
      closeOnClick: false
    });

    this.map.on('mousemove', this.parcelsFromOtherFarmsLayerId, (e) => {
      const fileFarmName = this.manualFarmField || e.features[0].properties[this.selectedFarmField] || '';
      const fileFarmCode = e.features[0].properties[this.selectedFarmCodeField] || '';
      const farmNameWithCode = fileFarmName + `${fileFarmCode ? ` (${fileFarmCode})` : ''}`;
      popup.setLngLat(e.lngLat).setHTML(farmNameWithCode).addTo(map);
    });

    this.map.on('mouseleave', this.parcelsFromOtherFarmsLayerId, () => {
      popup.remove();
    });

    const overlapPopup = new Popup({
      closeButton: false,
      closeOnClick: false
    });

    this.map.on('mousemove', this.overlapsLayerId, (e) => {
      const percentage = `Overlap percentage: ${e.features[0].properties.percent}%`;
      const equalAreas = `Is areas equal: ${e.features[0].properties.hasEqualAreas}`;
      overlapPopup.setLngLat(e.lngLat).setHTML(`${percentage}<br/>${equalAreas}`).addTo(map);
    });

    this.map.on('mouseleave', this.overlapsLayerId, () => {
      overlapPopup.remove();
    });

    this.createDrawLayer();

    if (this.shapeFarmNames.length) {
      const unValidated = this.getUnvalidatedFarm();
      if (unValidated) {
        this.handleShapeFarmNameChange(unValidated.featureName + '|' + unValidated.featureCode);
      }
    }

    this.changeBackground(this.selectedBackground);

    document.onkeyup = (e) => {
      if (e.shiftKey) {
        switch (e.code) {
          case 'KeyZ':
            this.applyFromHistory();
            break;
          case 'KeyN':
            this.goNext();
            break;
        }
      }
    };
  }

  saveFarmState(clearResults = false): void {
    if (this.selectedShapeFarmNameWithCode !== null && this.selectedShapeFarmNameWithCode !== undefined) {
      if (!this.editedFeaturesByFarmCodeName[this.selectedShapeFarmNameWithCode]) {
        this.editedFeaturesByFarmCodeName[this.selectedShapeFarmNameWithCode] = {
          file: [],
          db: [],
          dbParcels: this.dbParcels
        };
      }
      const allFeatures = this.draw.getAll().features;
      this.editedFeaturesByFarmCodeName[this.selectedShapeFarmNameWithCode].file = allFeatures.filter(
        (feature: Feature) => !feature.properties.parcelId
      );
      this.editedFeaturesByFarmCodeName[this.selectedShapeFarmNameWithCode].db = allFeatures.filter(
        (feature: Feature) => !!feature.properties.parcelId
      );
      if (clearResults) {
        delete this.editedFeaturesByFarmCodeName[this.selectedShapeFarmNameWithCode];
      }
    }
  }

  getUnvalidatedFarm(): FarmMapping {
    return this.shapeFarmNames.find((farmMapping: FarmMapping) => !farmMapping.validated);
  }

  approve(): void {
    const selectedFarmNameAndCode = this.getSelectedFarmNameAndCode();
    const shapeFarmName = this.shapeFarmNames.find((farmMapping: FarmMapping) => {
      const featureName =
        farmMapping.featureName !== null && farmMapping.featureName !== undefined
          ? farmMapping.featureName.toString()
          : '';
      return (
        featureName === selectedFarmNameAndCode.farmName &&
        (farmMapping.featureCode ? farmMapping.featureCode.toString() === selectedFarmNameAndCode.code : true)
      );
    });
    if (shapeFarmName) {
      shapeFarmName.validated = true;
    }

    const unValidated = this.getUnvalidatedFarm();
    if (unValidated) {
      this.handleShapeFarmNameChange(unValidated.featureName + '|' + unValidated.featureCode);
    } else {
      this.cancel();
    }
  }

  private clearHistory(): void {
    this.drawHistory = [];
    this.drawSelectedFeatureIds = null;
  }

  private saveToHistory(): void {
    const polygons = this.draw.getAll();
    if (this.drawHistory.length === 5) {
      this.drawHistory = this.drawHistory.slice(1);
    }
    this.drawHistory.push({
      polygons,
      result: cloneDeep(this.result)
    });
  }

  applyFromHistory(): void {
    if (this.drawHistory.length > 1) {
      const index = this.drawHistory.length - 2;
      const polygons = this.drawHistory[index].polygons;
      this.draw.deleteAll();
      this.draw.add(polygons);
      this.result = cloneDeep(this.drawHistory[index].result);
      this.drawHistory = this.drawHistory.slice(0, index + 1);
      this.drawSelectedFeatureIds = null;
      this.checkOverlapping();
    }
  }

  private simplifyPolygon(polygon: Feature<Polygon>): Feature<Polygon> {
    const cv = cleanCoords(polygon);
    return simplify(cv, { tolerance: 0.000001 });
  }

  makeSimple(): void {
    const features = this.draw.getSelected();
    const featuresIds: Id[] = [];
    const simplified: Feature<Polygon>[] = [];
    featureEach(features, (feature: Feature<Polygon>) => {
      featuresIds.push(feature.id);
      simplified.push(this.simplifyPolygon(feature));
    });
    if (featuresIds.length) {
      this.draw.delete(featuresIds);
      const simplifiedCollection = featureCollection(simplified);
      const addedFeatureIds = this.draw.add(simplifiedCollection);
      this.draw.changeMode('simple_select', {
        featureIds: addedFeatureIds
      });
      this.drawUpdate(simplifiedCollection);
    }
  }

  private revertReplaceModeFeature(): void {
    if (this.replaceModeFeature) {
      this.draw.add(featureCollection([this.replaceModeFeature]));
      this.replaceModeFeature = null;
    }
  }

  replace(): void {
    const collection = this.draw.getSelected();
    if (collection && collection.features && collection.features.length) {
      this.replaceModeFeature = collection.features[0];
      this.draw.delete(collection.features[0].id);
      this.draw.changeMode('draw_polygon');
      this.drawSelectedFeatureIds = null;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  private zoomToGeoJson(geojson: any): void {
    const geoBbox = bbox(geojson);
    this.map.fitBounds(
      [
        [geoBbox[0], geoBbox[3]],
        [geoBbox[2], geoBbox[1]]
      ],
      { duration: 0 }
    );
  }

  private goToFeature(overlap: OverlapMapping): void {
    this.zoomToGeoJson(overlap.intersect);
    this.currentProcessedId = overlap.feature.id + overlap.dbId;
    this.draw.changeMode('simple_select', {
      featureIds: [overlap.feature.id]
    });
    this.onFeaturesSelected([overlap.feature]);
  }

  goNext(): void {
    if (this.overlap.length === 0) {
      return;
    }
    if (!this.currentProcessedId) {
      this.goToFeature(this.overlap[0]);
      return;
    }
    const index = this.overlap.findIndex((overlap: OverlapMapping) => {
      const overlapId = overlap.feature.id + overlap.dbId;
      return overlapId === this.currentProcessedId;
    });
    if (index === -1 || index + 1 >= this.overlap.length) {
      this.goToFeature(this.overlap[0]);
      return;
    }
    this.goToFeature(this.overlap[index + 1]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private drawCreate(e: any): void {
    if (e && e.features && e.features.length) {
      if (this.replaceModeFeature) {
        Object.keys(this.replaceModeFeature.properties).forEach((key) => {
          this.draw.setFeatureProperty(e.features[0].id, key, this.replaceModeFeature.properties[key]);
        });
        const replacedFeature = this.draw.get(e.features[0].id);
        if (replacedFeature && replacedFeature.properties.parcelId) {
          const result = cloneDeep(this.result);
          result.editedDbParcels[replacedFeature.properties.parcelId] = {
            parcel: this.dbParcels.find((p: ParcelByBbox) => p.id === replacedFeature.properties.parcelId),
            feature: replacedFeature
          };
          this.result = result;
        }
        this.replaceModeFeature = null;
      }
      this.checkOverlapping().then(() => {
        this.saveToHistory();
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private drawUpdate(e: any): void {
    if (e && e.features && e.features.length) {
      e.features.forEach((f: Feature) => {
        if (f.properties.parcelId) {
          const result = cloneDeep(this.result);
          result.editedDbParcels[f.properties.parcelId] = {
            parcel: this.dbParcels.find((p: ParcelByBbox) => p.id === f.properties.parcelId),
            feature: f
          };
          this.result = result;
        }
      });
    }
    this.checkOverlapping().then(() => {
      this.saveToHistory();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private drawDelete(e: any): void {
    this.deleteFeatures(e && e.features ? e.features : []);
  }

  private deleteFeatures(features: Feature[], creationDate?: string): Promise<void> {
    if (features && features.length) {
      features.forEach((f: Feature) => {
        if (f.properties.parcelId) {
          const result = cloneDeep(this.result);
          delete result.editedDbParcels[f.properties.parcelId];
          result.deletedDbParcels[f.properties.parcelId] = {
            parcel: this.dbParcels.find((p: ParcelByBbox) => p.id === f.properties.parcelId),
            deletedDate: creationDate
              ? moment.utc(creationDate).subtract(1, 'day').endOf('day').toISOString()
              : moment.utc().startOf('day').toISOString()
          };
          this.result = result;
        }
      });
    }
    return this.checkOverlapping().then(() => {
      if (this.draw.getMode() !== 'paint') {
        this.saveToHistory();
        this.drawSelectedFeatureIds = null;
      }
    });
  }

  private drawModeChange(): void {
    if (this.replaceModeFeature) {
      this.revertReplaceModeFeature();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private drawSelectionChange(e: any): void {
    this.onFeaturesSelected(e ? e.features : []);
  }

  private onFeaturesSelected(features: Feature[]): void {
    const newSelectedFeaturesIds = features && features.length ? features.map((f: Feature) => f.id as string) : [];
    this.drawSelectedFeatureIds = newSelectedFeaturesIds.length ? newSelectedFeaturesIds : null;
    this.drawSelectedFileParcel =
      features && features.length === 1 && !features[0].properties.parcelId ? features[0] : null;
    this.updateDrawSelectedFileOverlaps();
  }

  cancel(): void {
    this.saveFarmState();

    const processedFarmNames = Object.keys(this.editedFeaturesByFarmCodeName);
    const notProcessedShapes = this.shape.features.filter((feature: Feature) => {
      const farmName = this.manualFarmField || feature.properties[this.selectedFarmField] || '';
      const farmCode = feature.properties[this.selectedFarmCodeField] || '';
      return !processedFarmNames.includes(farmName + '|' + farmCode);
    });
    const processedShapes = Object.values(this.editedFeaturesByFarmCodeName).reduce(
      (acc, c) => [...acc, ...c.file],
      []
    );

    this.selectedShapeFarmNameWithCode = null;
    this.$emit('onEdited', this.result, featureCollection([...notProcessedShapes, ...processedShapes]));
    document.onkeyup = null;
  }
  deleteOverlappings() {
    this.deleteExisting = true;
    const result = cloneDeep(this.result);
    const addedFeatures: Feature[] = [];
    const modifiedShapeParcelDates: { props: any; plantingDate: string }[] = [];
    this.overlap.forEach((overlap) => {
      if (overlap.dbId) {
        this.draw.delete([overlap.feature2.id]);
        let deletionDate = this.parseCreationDate(overlap.feature.properties);
        if (overlap.dbSurveys) {
          const droneSurveys = overlap.dbSurveys.filter((x: Survey) => x.Source === 'drone');
          if (droneSurveys.length > 0) {
            deletionDate = droneSurveys.sort((a, b) => moment.utc(b.Date).valueOf() - moment.utc(a.Date).valueOf())[0]
              .Date;
            deletionDate = moment.utc(deletionDate).add(1, 'days').toISOString(); //Later 1 week subtracted
          } else {
            deletionDate = moment.utc(deletionDate).add(-1, 'days').toISOString();
          }
        } else {
          deletionDate = moment.utc(deletionDate).add(-1, 'days').toISOString();
        }
        if (overlap.dbId) {
          result.deletedDbParcels[overlap.dbId] = {
            parcel: this.dbParcels.find((p: ParcelByBbox) => p.id === overlap.dbId),
            deletedDate: deletionDate
          };
          if (!addedFeatures.find((x) => JSON.stringify(x.geometry) === JSON.stringify(overlap.feature.geometry))) {
            addedFeatures.push(overlap.feature);
            modifiedShapeParcelDates.push({
              props: overlap.feature2.properties,
              plantingDate: moment.utc(deletionDate).add(1, 'days').toISOString()
            });
          }
        }
      }
    });
    this.confirmDelete(Object.keys(result.deletedDbParcels).length).then(async () => {
      this.result = result;
      const dbFeaturesWithSurveys: Feature[] = this.getDbFeaturesWithSurveys();
      this.updateDbFeaturesWithSurveys(dbFeaturesWithSurveys);
      this.draw.add(featureCollection(addedFeatures));
      await this.upload();
      if (modifiedShapeParcelDates.length > 0) this.$emit('modifiedDates', modifiedShapeParcelDates);
      return this.checkOverlapping().then(() => {
        if (this.draw.getMode() !== 'paint') {
          this.saveToHistory();
          this.drawSelectedFeatureIds = null;
        }
      });
    });
  }

  private confirmDelete(parcelsToDelete: number): Promise<void> {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: 'Are you sure?',
        content: parcelsToDelete + ' parcels will be deleted.',
        onOk() {
          resolve();
        },
        onCancel() {
          reject();
        }
      });
    });
  }
}
