
import { Component, Vue } from 'vue-property-decorator';
import GlobalLoader from '@/components/GlobalLoader.vue';

@Component({
  components: {
    GlobalLoader
  }
})
export default class App extends Vue {
  mounted(): void {
    this.openFeedback();
  }

  openFeedback(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userSnap = (window as any).userSnap;
    if (userSnap) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      userSnap.on('open', (event: any) => {
        event.api.setValue('visitor', this.$store.state.userInfo ? this.$store.state.userInfo.Email : null);
        event.api.setValue('custom', this.$store.state.userInfo);
      });
    }
  }
}
