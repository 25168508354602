import { Feature } from '@turf/helpers';

let idCounter = 0;
const fakeIdMask = '_fake';

export function getFakeId(): string {
  idCounter++;
  return `${idCounter}${fakeIdMask}`;
}

export function isIdFake(id: string): boolean {
  return id.includes(fakeIdMask);
}

export function getCutStageFromFeature(
  feature: Feature,
  selectedCutStageField: string,
  cutStageMapping: Record<string, number>
): number {
  if (selectedCutStageField && cutStageMapping) {
    const cutStageValue = cutStageMapping[feature.properties[selectedCutStageField]];
    return cutStageValue !== undefined && cutStageValue !== null ? cutStageValue : null;
  }
  return null;
}
