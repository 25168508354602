import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {
  Alert,
  AutoComplete,
  Form,
  Button,
  Card,
  Checkbox,
  Divider,
  Input,
  Spin,
  Modal,
  Select,
  Table,
  Tag,
  Radio
} from 'ant-design-vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import moment from 'moment-timezone';

Vue.config.productionTip = false;

Vue.use(AutoComplete);
Vue.use(Form);
Vue.use(Alert);
Vue.use(Button);
Vue.use(Card);
Vue.use(Divider);
Vue.use(Input);
Vue.use(Modal);
Vue.use(Select);
Vue.use(Table);
Vue.use(Tag);
Vue.use(Spin);
Vue.use(Radio);
Vue.use(Checkbox);

const injectUserSnap = () => {
  (window as any).onUsersnapCXLoad = function (api: any) {
    api.init({ button: null });
    (window as any).userSnap = api;
    init();
  };
  const script = document.createElement('script') as any;
  script.defer = 1;
  script.nonce = 'usersnap';
  script.onerror = function () {
    init();
  };
  script.src =
    'https://widget.usersnap.com/global/load/348480da-d057-4813-a9ab-d1221d33e825?onload=onUsersnapCXLoad&n=usersnap';
  document.getElementsByTagName('head')[0].appendChild(script);
};
injectUserSnap();

moment.tz.setDefault('UTC');

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    ignoreErrors: ['Request failed with status code 403', 'Request failed with status code 404'],
    logErrors: true,
    environment: process.env.VUE_APP_ENVIRONMENT,
    debug: process.env.VUE_APP_ENVIRONMENT !== 'prod',
    release: process.env.VUE_APP_RELEASE,
    integrations: [
      new BrowserTracing({
        // TODO: we don't track backend APIs now as it's creating too many OPTIONS/Preflight request
        tracingOrigins: ['dont-track-anything']
      })
    ],
    trackComponents: true,
    tracesSampleRate: 0.1
  });
}
const init = () => {
  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app');
};
