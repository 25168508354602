import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';
import { UserInfo } from '@/interfaces/userInfo';
import { State } from '@/interfaces/state';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins:
    process.env.VUE_APP_DEBUG_MODE === 'true'
      ? [
          createLogger({
            logMutations: false
          })
        ]
      : [],
  strict: process.env.NODE_ENV !== 'production',
  state: {
    showGlobalLoader: false,
    globalLoaderMessage: '',
    userInfo: null
  },
  mutations: {
    showGlobalLoader(state: State, payload: boolean) {
      state.showGlobalLoader = payload;
    },
    setGlobalLoaderMessage(state: State, payload: string) {
      state.globalLoaderMessage = payload;
    },
    setUserInfo: (state: State, userInfo: UserInfo): void => {
      state.userInfo = userInfo;
    }
  },
  actions: {
    showGlobalLoader({ commit }, message?: string) {
      commit('showGlobalLoader', true);
      commit('setGlobalLoaderMessage', message || 'Loading...');
    },
    setGlobalLoaderMessage({ commit }, message: string) {
      commit('setGlobalLoaderMessage', message);
    },
    hideGlobalLoader({ commit }) {
      commit('showGlobalLoader', false);
    },
    setUserInfo: ({ commit }, userInfo: UserInfo): void => {
      commit('setUserInfo', userInfo);
    }
  },
  modules: {}
});
