
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CutStageMapping extends Vue {
  @Prop() mapping: Record<string, number>;

  public handleMappingChange(cutStage: string, event: InputEvent): void {
    const value = (event.target as HTMLInputElement).value;
    this.$emit('onChange', cutStage, value !== '' ? parseInt(value) : null);
  }
}
