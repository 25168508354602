export enum CarProperty {
  CodImovel = 'cod_imovel',
  NomMunici = 'nom_munici',
  Situacao = 'situacao',
  CondicaoI = 'condicao_i',
  CodEstado = 'cod_estado',
  IndStatus = 'ind_status',
  DesCondic = 'des_condic',
  Municipio = 'municipio'
}
