
import { Component, Vue } from 'vue-property-decorator';
import { feature, Feature, FeatureCollection, featureCollection, MultiPolygon, polygon, Polygon } from '@turf/helpers';
import simplify from '@turf/simplify';
import area from '@turf/area';
import truncate from '@turf/truncate';
import union from '@turf/union';
import cleanCoords from '@turf/clean-coords';
import moment from 'moment';
import { Country } from '@/interfaces/country';
import { Unit } from '@/interfaces/unit';
import { Mapping } from '@/interfaces/mapping';
import { Farm } from '@/interfaces/farm';
import { FarmMapping } from '@/interfaces/farmMapping';
import { Parcel, SeasonData } from '@/interfaces/parcel';
import { ParcelMapping } from '@/interfaces/parcelMapping';
import * as FileSaver from 'file-saver';
import MapEntityModal from '@/components/MapEntityModal.vue';
import { Item } from '@/interfaces/item';
import CreateEntityModal from '@/components/CreateEntityModal.vue';
import API from '@/services/api';
import { CropType } from '@/interfaces/cropType';
import { message } from 'ant-design-vue';
import ManageOverlappingModal from '@/components/ManageOverlappingModal.vue';
import { OverlapMapping } from '@/interfaces/overlapMapping';
import { getCutStageFromFeature, getFakeId, isIdFake } from '@/services/utils';
import { FarmRequest, ParcelRequest, UnitRequest, UploadRequest } from '@/interfaces/uploadRequest';
import ManageFarmOverlappingModal from '@/components/ManageFarmOverlappingModal.vue';
import { FarmOverlapMapping } from '@/interfaces/farmOverlapMapping';
import { SourceParcelOverlapMapping } from '@/interfaces/sourceParcelOverlapMapping';
import ManageSourceParcelOverlappingModal from '@/components/ManageSourceParcelOverlappingModal.vue';
import kinks from '@turf/kinks';
import buffer from '@turf/buffer';
import ShapeEditingModal from '@/components/ShapeEditingModal2.vue';
import * as shp from 'shpjs';
import JSZip from 'jszip';
import { ShapeEditingResult } from '@/interfaces/shapeEditingResult';
import csvtojson from 'csvtojson';
import * as Sentry from '@sentry/vue';
import polygonClipping from 'polygon-clipping';
import CustomFieldsMapping from '@/components/CustomFieldsMapping.vue';
import { Organization } from '@/interfaces/organization';
import { CustomFieldMapping } from '@/interfaces/customFieldMapping';
import { SurveySource } from '@/interfaces/surveySource';
import SelectSeason from '@/components/SelectSeason.vue';
import CutStageMapping from '@/components/CutStageMapping.vue';
import { getDefaultCutStageValue, getUniqCutStages } from '@/services/cut-stage-utils';
import { CutStageMappingByOrganizations } from '@/interfaces/cutStageMappingByOrganizations';
import SeasonValidationResultsModal from '@/components/SeasonValidationResultsModal.vue';
import { SeasonValidationOutput } from '@/interfaces/seasonValidation';
import { UploadType } from '@/interfaces/uploadType';
import { CarProperty } from '@/interfaces/carProperty';

const farmOverlapWorker = new Worker(new URL('../services/farm-overlap.worker.ts', import.meta.url));
const sourceParcelOverlapWorker = new Worker(new URL('../services/source-parcel-overlap.worker.ts', import.meta.url));
const clusteringWorker = new Worker(new URL('../services/clustering.worker.ts', import.meta.url));
const parcelsSeasonValidationWorker = new Worker(
  new URL('../services/parcels-season-validation.worker.ts', import.meta.url)
);

@Component({
  components: {
    MapEntityModal,
    CreateEntityModal,
    ManageOverlappingModal,
    ManageFarmOverlappingModal,
    ManageSourceParcelOverlappingModal,
    ShapeEditingModal,
    CustomFieldsMapping,
    SelectSeason,
    CutStageMapping,
    SeasonValidationResultsModal
  }
})
export default class Upload extends Vue {
  shape: FeatureCollection = null;
  private shapeName: string = null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  properties: object = {};
  shapeFields: string[] = [];
  cropTypes: CropType[] = [];
  countries: Country[] = [];
  entities: Organization[] = [];
  units: Unit[] = [];
  farms: Farm[] = [];
  parcels: Parcel[] = [];
  selectedCountryId: string = null;
  selectedEntityId: string = null;
  selectedUnit: Unit = null;
  selectedSeason: string = null;
  private selectedFarmField: string = null;
  private selectedFarmCodeField: string = null;
  private selectedParcelIdField: string = null;
  private selectedParcelNameField: string = null;
  private selectedParcelAreaField: string = null;
  selectedCropTypeId: string = null;
  private selectedPlantingDateField: string = null;
  private selectedDeletionDateField: string = null;
  private selectedCreationDateField: string = null;
  private selectedHarvestingDateField: string = null;
  private selectedNextHarvestDateField: string = null;
  private selectedVarietyField: string = null;
  private selectedZoneField: string = null;
  private selectedProdEnvField: string = null;
  selectedCutStageField: string = null;
  cutStageMapping: Record<string, number> = null;
  cutStagesWithOutMapping: string[] = [];
  cutStageMappingByOrganizations: CutStageMappingByOrganizations = null;
  private selectedRotationField: string = null;
  propertyDateMask = 'YYYY-MM-DD';
  propertyDeletionDateMask = 'YYYY-MM-DD';
  propertyHarvestingDateMask = 'YYYY-MM-DD';
  propertyNextHarvestDateMask = 'YYYY-MM-DD';
  propertyCreationDateMask = 'YYYY-MM-DD';
  manualPlantingDateField = '';
  manualDeletionDateField = '';
  manualCreationDateField = '';
  manualHarvestingDateField = '';
  manualNextHarvestDateField = '';
  manualFarmField = '';
  parsedPropertyDate = '';
  parsedPropertyDeletionDate = '';
  parsedPropertyCreationDate = '';
  parsedPropertyHarvestingDate = '';
  parsedPropertyNextHarvestDate = '';
  shapeFarmNames: FarmMapping[] = [];
  shapeParcelNames: ParcelMapping[] = [];
  private readonly plantingDateMask = 'YYYY-MM-DD';
  private readonly maxOldPlantingDateYears = 60;
  createFarmItemName: string = null;
  createFarmItemCode: string = null;
  createParcelItemName: string = null;
  mapFarmItemName: string = null;
  mapParcelItemName: string = null;
  kinksFeatures: Feature[] = [];
  shapeEditingResult: ShapeEditingResult = null;
  private uploadRequest: UploadRequest = null;

  private DEFAULT_CUSTOM_PARCEL_ATTRIBUTES = ['Owner', 'PlantingSystem', 'HarvestBlock'];
  private isSuccessfullyUploaded = false;
  private isManageOverlappingVisible = false;
  public farmOverlapMapping: FarmOverlapMapping[] = [];
  public sourceParcelOverlapMapping: SourceParcelOverlapMapping[] = [];
  private isManageFarmOverlappingVisible = false;
  private isManageSourceParcelOverlappingVisible = false;
  private isShapeEditingVisible = false;
  private modifiedDates: { props: any; plantingDate: string }[] = [];
  private shapeParcelNamesArea: { [key: string]: string[] } = {};
  private shapeParcelUniqueNameCounter = 0;
  private propertiesPlantingDateName = 'gamaya_planting_date';
  private propertiesCreationDateName = 'gamaya_creation_date';
  private propertiesDeletionDateName = 'gamaya_deletion_date';
  private propertiesHarvestingDateName = 'gamaya_harvesting_date';
  private propertiesNextHarvestDateName = 'gamaya_next_harvest_date';
  private propertiesRotationName = 'gamaya_rotation';
  private propertiesVarietyName = 'gamaya_variety';
  private propertiesZoneName = 'gamaya_zone';
  private propertiesEnvironmentName = 'gamaya_environment';
  private propertiesCustomFieldsName = 'gamaya_custom_fields';
  private propertiesCropTypeName = 'gamaya_crop_type';
  private propertiesAreaName = 'gamaya_area';
  private propertiesParcelName = 'gamaya_parcel';
  private propertiesFarmName = 'gamaya_farm';
  private propertiesEntityName = 'gamaya_entity';
  private propertiesDomainName = 'gamaya_domain';

  private testPolygon = polygon([
    [
      [13.0050659, 82.0135588],
      [13.0586242, 82.0122231],
      [13.0421447, 82.0198529],
      [13.0050659, 82.0135588]
    ]
  ]);

  private customFieldsMapping: CustomFieldMapping[] = [];

  public seasonValidationResult: SeasonValidationOutput = null;
  private seasonValidationResultModalPromise: (value: boolean) => void;

  public UploadType = UploadType;
  public selectedUploadType: UploadType = UploadType.PARCEL;
  private carShapes: FeatureCollection = null;
  public carShapePropertyName = 'COD_IMOVEL';

  farmColumns = [
    {
      title: 'Name',
      dataIndex: 'featureName'
    },
    {
      title: 'Code',
      dataIndex: 'featureCode'
    },
    {
      title: 'DB Name',
      dataIndex: 'dbName'
    },
    {
      title: 'DB Code',
      dataIndex: 'dbCode'
    },
    {
      title: 'Action',
      scopedSlots: { customRender: 'action' }
    }
  ];
  MAX_SHAPE_AREA = 15000000;

  isClusteringEnabled = false;
  isFormatNematodeEnabled = false;
  private refreshSessionInterval = 600000; // millisecond, 10 min

  public getParcelColumns(): Record<string, any>[] {
    const items: Record<string, any>[] = [
      {
        title: 'Parcel id',
        dataIndex: 'featureId'
      },
      {
        title: 'Parcel name',
        dataIndex: 'featureName'
      },
      {
        title: 'DB',
        scopedSlots: { customRender: 'dbName' }
      },
      {
        title: 'Parcel area',
        dataIndex: 'featureArea'
      },
      {
        title: 'Planting date',
        dataIndex: 'plantingDate'
      },
      {
        title: 'Variety',
        dataIndex: 'variety'
      },
      {
        title: 'Zone',
        dataIndex: 'zone'
      },
      {
        title: 'Environment',
        dataIndex: 'environment'
      },
      {
        title: 'Cut Stage',
        dataIndex: 'cutStage'
      }
    ];

    this.customFieldsMapping.forEach((field: CustomFieldMapping) => {
      items.push({
        title: field.parcelField,
        dataIndex: field.parcelField
      });
    });

    items.push({
      title: 'Area',
      dataIndex: 'area',
      scopedSlots: { customRender: 'area' }
    });

    items.push({
      title: 'Action',
      scopedSlots: { customRender: 'action' }
    });

    return items;
  }

  private get isSelectedOrganizationSatellite(): boolean {
    const organization = this.entities.find((org: Organization) => org.id === this.selectedEntityId);
    return organization?.Type === SurveySource.SATELLITE;
  }

  async mounted(): Promise<void> {
    this.$store.dispatch('showGlobalLoader');
    try {
      const response = await API.authorize('shape-uploader');
      const userInfo = response.UserInfo;
      this.$store.dispatch('setUserInfo', userInfo);
      Sentry.setUser({ email: userInfo.Email, username: userInfo.FirstName + ' ' + userInfo.LastName });
      Promise.all([API.getCountries(), API.getCropTypes()])
        .then((result) => {
          this.countries = result[0];
          this.cropTypes = result[1];
        })
        .finally(() => {
          this.$store.dispatch('hideGlobalLoader');
        });
    } catch (error) {
      window.location.href = `${process.env.VUE_APP_AUTH_SERVER}?redir=${encodeURIComponent(window.location.href)}`;
    }

    setInterval(() => {
      API.refreshSession();
    }, this.refreshSessionInterval);
  }

  getSortedShapeParcelNames(): Record<string, any>[] {
    const items = [...this.shapeParcelNames];
    items.sort((a: ParcelMapping, b: ParcelMapping) => (a.dbId && !b.dbId ? 1 : -1));
    return items.map((item: ParcelMapping) => {
      return {
        ...item,
        ...item.customFields
      };
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  filterOption(input: string, option: any): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  get kinksErrorMessage(): string {
    return `Provided geoJSON file contains ${this.kinksFeatures.length} self-intersection polygons! They are skipped. See console.log for details.`;
  }

  get duplicatedCarShapesInFileMessage(): string {
    if (!this.duplicatedCarShapesInFile) {
      return '';
    }
    const values = Object.keys(this.duplicatedCarShapesInFile);
    const items = values.map((value: string) => `${value} (${this.duplicatedCarShapesInFile[value] + 1} times)`);
    return `Provided files contains ${values.length} duplicated ${this.carShapePropertyName} with values: ${items.join(
      ', '
    )}. Only one shape will be uploaded.`;
  }

  get duplicatedCarShapesInDBMessage(): string {
    if (!this.duplicatedCarShapesInDB) {
      return '';
    }
    const values = Object.keys(this.duplicatedCarShapesInDB);
    const items = values.map((value: string) => `${value} (${this.duplicatedCarShapesInDB[value]} times)`);
    return `Provided files contains ${
      values.length
    } duplicated shapes which are already uploaded with values: ${items.join(
      ', '
    )}. They will be replaced with the latest shape.`;
  }

  async handleChangeCutStageMappingFile(event: Event): Promise<void> {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length) {
      try {
        this.cutStageMappingByOrganizations = JSON.parse(
          (await this.readFile(files[0], true)) as string
        ) as CutStageMappingByOrganizations;
        if (this.selectedCutStageField) {
          this.handleCutStageChange(this.selectedCutStageField);
        }
      } catch (e) {
        message.error('Something wrong with provided file, check console for details.');
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }

  async handleChange(event: Event): Promise<void> {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length) {
      await this.$store.dispatch('showGlobalLoader', 'Loading and validating file...');
      this.shapeName = files[0].name;
      let jsonData: FeatureCollection = featureCollection([]);
      try {
        const hasMultipleFiles = files.length > 1;
        let isAllZipFiles = hasMultipleFiles;
        let hasShpFile = false;
        if (hasMultipleFiles) {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.name.toLowerCase().endsWith('.shp')) {
              this.shapeName = file.name;
              hasShpFile = true;
              break;
            }
            isAllZipFiles = isAllZipFiles && file.name.toLowerCase().endsWith('.zip');
          }
        }
        if (hasShpFile) {
          jsonData = (await this.shpFilesToGeoJson(files)) as FeatureCollection;
        } else {
          const processFiles = isAllZipFiles ? files : [files[0]];
          for (let i = 0; i < processFiles.length; i++) {
            const file = processFiles[i];
            let fileJsonData: FeatureCollection = featureCollection([]);
            if (file.name.toLowerCase().endsWith('.zip')) {
              fileJsonData = await this.shpZipToGeoJson(await this.readFile(file, false));
            } else if (file.name.toLowerCase().endsWith('.csv')) {
              fileJsonData = await this.csvToJson((await this.readFile(file, true)) as string);
            } else {
              fileJsonData = JSON.parse((await this.readFile(file, true)) as string) as FeatureCollection;
              fileJsonData = (await this.transform(fileJsonData)) as FeatureCollection;
            }
            jsonData = featureCollection([...jsonData.features, ...fileJsonData.features]);
          }
        }
      } catch (e) {
        message.error('Something wrong with provided files, check console for details.');
        // eslint-disable-next-line no-console
        console.error(e);
      }
      await this.onJsonDataLoaded(jsonData);
      await this.$store.dispatch('hideGlobalLoader');
    }
  }

  private async csvToJson(text: string): Promise<FeatureCollection> {
    //Used noheader, eventhough there is header,
    //as same column heading is used in multiple columns.
    const rows = await csvtojson({ noheader: true }).fromString(text);
    let jsonData = featureCollection([]);
    let firstRow = true;
    rows.forEach((row) => {
      if (firstRow) {
        firstRow = false;
        return true;
      }
      let points = Object.keys(row).length - 10;
      const coords = [];
      for (let i = 0; i < points; i += 2) {
        if (row['field' + (5 + i)].trim().length == 0) break;
        coords.push([parseFloat(row['field' + (5 + i + 1)]), parseFloat(row['field' + (5 + i)])]);
      }
      coords.push([parseFloat(row['field6']), parseFloat(row['field5'])]);

      if (coords.filter((x) => x.filter((y) => y === 0 || isNaN(y)).length > 0).length > 0) {
        return true;
      }
      jsonData.features.push({
        type: 'Feature',
        properties: {
          Parcel: row['field1'],
          Status: row['field2'],
          PlantingDate: row['field3'],
          Variety: row['field' + (5 + points)],
          Circle: row['field' + (8 + points)],
          Village: row['field' + (10 + points)],
          VillageCode: row['field' + (9 + points)]
        },
        geometry: { type: 'Polygon', coordinates: [coords] }
      });
    });
    return jsonData;
  }

  private async transform(json: any): Promise<any> {
    if (
      json.crs &&
      json.crs.properties &&
      json.crs.properties.name &&
      json.crs.properties.name.indexOf(':4326') === -1 &&
      json.crs.properties.name.indexOf('CRS84') === -1
    ) {
      return await API.transform(json);
    }
    return json;
  }

  private addNematodeProperties(): void {
    this.shape.features.forEach((feature: Feature, index) => {
      feature.properties['ID'] = index + 1;
      feature.properties['farmname'] = this.shapeName.replace(/\.[^/.]+$/, '');
      feature.properties['variety'] = 'soy';
      feature.properties['plantingdate'] = '2015-01-01';
    });
  }

  private async onJsonDataLoaded(jsonData: FeatureCollection): Promise<void> {
    this.shape = this.fixShape(jsonData);

    if (this.isFormatNematodeEnabled) {
      this.addNematodeProperties();
    }

    if (this.isClusteringEnabled) {
      const clusteredData = await this.clustering(this.shape);
      this.shape = this.scaleShape(clusteredData);
    }

    if (this.shape && this.shape.features && this.shape.features.length) {
      if (this.selectedUploadType === UploadType.CAR) {
        this.shape.features.forEach((feature: Feature) => {
          if (feature.properties[CarProperty.IndStatus]) {
            feature.properties[CarProperty.Situacao] = feature.properties[CarProperty.IndStatus];
          }
          if (feature.properties[CarProperty.DesCondic]) {
            feature.properties[CarProperty.CondicaoI] = feature.properties[CarProperty.DesCondic];
          }
          if (feature.properties[CarProperty.Municipio]) {
            feature.properties[CarProperty.NomMunici] = feature.properties[CarProperty.Municipio];
          }
        });
      }

      const properties = { ...this.shape.features[0].properties };
      this.properties = properties;
      this.shapeFields = Object.keys(properties);
    }
  }

  private validatePrjFile(prjStr: string): boolean {
    if (prjStr.indexOf('D_South_American_1969') !== -1) {
      message.error('South American Datum is not supported, please convert file to WGS84');
      return false;
    }
    return true;
  }

  private async shpFilesToGeoJson(files: FileList) {
    if (files.length !== 3) {
      message.error('All 3 shape file components must be selected.');
      return;
    }
    let shpBuffer, prjStr, dbf: any;
    for (let i = 0; i < files.length; i++) {
      const fileName = files[i].name.toLowerCase();
      if (fileName.endsWith('.shp')) {
        shpBuffer = await this.readFile(files[i], false);
      } else if (fileName.endsWith('.prj')) {
        prjStr = await this.readFile(files[i], true);
      } else if (fileName.endsWith('.dbf')) {
        dbf = await this.readFile(files[i], false);
      }
    }
    if (!shpBuffer || !dbf || !prjStr) {
      message.error('Invalid shape file data');
      return null;
    }
    if (!this.validatePrjFile(prjStr as string)) {
      return null;
    }
    const shapes = shp.combine([shp.parseShp(shpBuffer, prjStr), shp.parseDbf(dbf)]);
    if (!shapes) {
      message.error('Invalid shape file data');
      return null;
    }
    return shapes;
  }

  private async readFile(file: File, isText: boolean) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      if (isText) fileReader.readAsText(file);
      else fileReader.readAsArrayBuffer(file);
    });
  }
  private async shpZipToGeoJson(input: any) {
    try {
      const zip = new JSZip();
      const data = await zip.loadAsync(input);
      let validFile = true;
      let prjFile = null;
      ['shp', 'prj', 'dbf'].map((ext) => {
        const file = Object.keys(data.files).find((key) => key.slice(-3).toLowerCase() === ext);
        if (!file) {
          message.error(ext + ' file must exist');
          validFile = false;
          return null;
        }
        if (ext === 'prj') {
          prjFile = file;
        }
      });
      if (prjFile) {
        const prjStr = await data.files[prjFile].async('string');
        if (!this.validatePrjFile(prjStr)) {
          return null;
        }
      }
      if (validFile) {
        return await shp(input);
      }
    } catch (err) {
      message.error('Invalid shape file. Error: ' + err);
    }
    return null;
  }

  private isValidPolygon(polygon: Feature<Polygon>): boolean {
    const rings = polygon.geometry.coordinates;
    if (rings.length === 0) {
      return false;
    }
    for (let i = 0; i < rings.length; i++) {
      if (rings[i].length < 4) {
        return false;
      }
    }
    return true;
  }

  private simplifyPolygon(polygon: Feature): Feature {
    try {
      const tv = truncate(polygon, { precision: 8 });
      const cv = cleanCoords(tv);
      if (polygon.geometry.type === 'Polygon' && !this.isValidPolygon(cv)) {
        return null;
      }
      if (kinks(cv).features.length === 0) {
        // extra check if polygon is valid
        union(cv, this.testPolygon);
        return simplify(cv, { tolerance: 0.00000001, highQuality: true });
      }
      this.kinksFeatures.push(polygon);
      // eslint-disable-next-line no-console
      console.error('kinks', JSON.stringify(cv));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err, JSON.stringify(polygon));
    }
    return null;
  }

  private scaleShape(shape: FeatureCollection): FeatureCollection {
    let features: Array<Feature<Polygon>> = [];
    shape.features.forEach((feature: Feature) => {
      const buffered = buffer(feature, -5, { units: 'meters' }) as Feature<Polygon>;
      features.push(buffered);
    });
    return featureCollection(features);
  }

  private fixShape(shape: FeatureCollection): FeatureCollection {
    this.kinksFeatures = [];
    if (shape && shape.features && shape.features.length) {
      const features: Array<Feature> = [];
      shape.features.forEach((feature: Feature) => {
        if (
          feature.geometry.type === 'Polygon' ||
          (feature.geometry.type === 'MultiPolygon' && this.isSelectedOrganizationSatellite)
        ) {
          const simplified = this.simplifyPolygon(feature);
          if (simplified) {
            features.push(simplified);
          }
        }
        if (feature.geometry.type === 'MultiPolygon' && !this.isSelectedOrganizationSatellite) {
          const multiPolygon = feature as Feature<MultiPolygon>;
          multiPolygon.geometry.coordinates.forEach((coords) => {
            const simplified = this.simplifyPolygon(polygon(coords, { ...feature.properties }));
            if (simplified) {
              features.push(simplified);
            }
          });
        }
      });
      if (features.length) {
        return featureCollection(features);
      }
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parsePlantingDate(properties: any): string {
    if (this.modifiedDates.length > 0) {
      const match = this.modifiedDates.filter((x) => JSON.stringify(x.props) === JSON.stringify(properties));
      if (match.length > 0) {
        return match[0].plantingDate;
      }
    }
    let date = moment.utc(
      this.manualPlantingDateField || properties[this.selectedPlantingDateField],
      this.propertyDateMask
    );
    if (!date.isValid()) {
      date = moment.utc().startOf('year');
    }
    return date.format(this.plantingDateMask);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseCreationDate(properties: any): string {
    let date = moment.utc(
      this.manualCreationDateField || properties[this.selectedCreationDateField],
      this.propertyCreationDateMask
    );
    if (!date.isValid()) {
      date = moment.utc().startOf('year');
    }
    return date.format(this.plantingDateMask);
  }

  private parseDeletionDate(properties: any): string {
    let date = moment.utc(
      this.manualDeletionDateField || properties[this.selectedDeletionDateField],
      this.propertyDeletionDateMask
    );
    if (!date.isValid()) {
      date = moment.utc([2100, 0, 1]);
    }
    return date.format(this.plantingDateMask);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseHarvestingDate(properties: any): string {
    let date = moment.utc(
      this.manualHarvestingDateField || properties[this.selectedHarvestingDateField],
      this.propertyHarvestingDateMask
    );
    if (!date.isValid()) {
      return null;
    }
    return date.format(this.plantingDateMask);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseNextHarvestDate(properties: any): string {
    let date = moment.utc(
      this.manualNextHarvestDateField || properties[this.selectedNextHarvestDateField],
      this.propertyNextHarvestDateMask
    );
    if (!date.isValid()) {
      return null;
    }
    return date.format(this.plantingDateMask);
  }
  handleCropTypeChange(id: string): void {
    this.selectedCropTypeId = id;
  }
  handleVarietyChange(name: string): void {
    this.selectedVarietyField = name;
  }
  handleZoneChange(name: string): void {
    this.selectedZoneField = name;
  }

  handleCutStageChange(name: string): void {
    this.selectedCutStageField = name;

    const cutStagesWithOutMapping: string[] = [];
    const mapping: Record<string, number> = {};
    const cutStages = getUniqCutStages(this.shape.features, this.selectedCutStageField);
    cutStages.forEach((cutStage: string) => {
      mapping[cutStage] = getDefaultCutStageValue(cutStage, this.cutStageMappingByOrganizations, this.selectedEntityId);
      if (this.cutStageMappingByOrganizations && mapping[cutStage] === null) {
        cutStagesWithOutMapping.push(cutStage);
      }
    });
    this.cutStageMapping = mapping;
    this.cutStagesWithOutMapping = cutStagesWithOutMapping;
  }
  onChangeCutStageMapping(cutStage: string, value: number): void {
    this.cutStageMapping = {
      ...this.cutStageMapping,
      [cutStage]: value
    };
  }
  handleProdEnvChange(name: string): void {
    this.selectedProdEnvField = name;
  }
  handleRotationChange(name: string): void {
    this.selectedRotationField = name;
  }
  handlePlantingDateChange(name: string): void {
    this.selectedPlantingDateField = name;
    this.onParsePlantingDate();
  }

  handleDeletionDateChange(name: string): void {
    this.selectedDeletionDateField = name;
    this.onParseDeletionDate();
  }

  handleCreationDateChange(name: string): void {
    this.selectedCreationDateField = name;
    this.onParseCreationDate();
  }

  handleHarvestingDateChange(name: string): void {
    this.selectedHarvestingDateField = name;
    this.onParseHarvestingDate();
  }

  handleNextHarvestDateChange(name: string): void {
    this.selectedNextHarvestDateField = name;
    this.onParseNextHarvestDate();
  }

  handleFarmFieldChange(name: string): void {
    this.selectedFarmField = name;
    this.onSelectFarm();
  }

  handleFarmCodeFieldChange(code: string): void {
    this.selectedFarmCodeField = code;
    this.onSelectFarm();
  }

  handleParcelIdFieldChange(id: string): void {
    this.selectedParcelIdField = id;
    this.onSelectParcel();
  }

  handleParcelNameFieldChange(name: string): void {
    this.selectedParcelNameField = name;
    this.onSelectParcel();
  }

  handleParcelAreaFieldChange(name: string): void {
    this.selectedParcelAreaField = name;
    this.onSelectParcel();
  }

  public onUpdateCustomFields(fields: CustomFieldMapping[]): void {
    this.customFieldsMapping = fields;
  }

  private get customParcelFields(): string[] {
    if (this.selectedEntityId && this.entities.length) {
      const organization = this.entities.find(({ id }) => id === this.selectedEntityId);
      if (organization?.CustomParcelAttributes?.length) {
        return organization.CustomParcelAttributes;
      }
    }
    return this.DEFAULT_CUSTOM_PARCEL_ATTRIBUTES;
  }

  handleCountryChange(id: string): void {
    this.selectedCountryId = id;
    this.$store.dispatch('showGlobalLoader', 'Loading organizations...');
    API.getOrganizations(id)
      .then((entities: Organization[]) => {
        this.entities = entities;
        this.selectedEntityId = null;
      })
      .finally(() => {
        this.$store.dispatch('hideGlobalLoader');
      });
  }

  handleEntityChange(id: string): void {
    this.selectedEntityId = id;
    this.selectedUnit = null;

    if (this.selectedCutStageField) {
      this.handleCutStageChange(this.selectedCutStageField);
    }

    this.$store.dispatch('showGlobalLoader', 'Loading units...');
    API.getUnits(id)
      .then((units: Unit[]) => {
        this.units = units;
      })
      .finally(() => {
        this.$store.dispatch('hideGlobalLoader');
      });
  }

  async handleUnitChange(id: string): Promise<void> {
    this.selectedUnit = this.units.find((unit: Unit) => unit.id === id);

    if (this.selectedUploadType === UploadType.PARCEL) {
      await this.$store.dispatch('showGlobalLoader', 'Loading farms...');
      const farmsResponse = await API.getFarms(id);
      const farms = farmsResponse || [];
      farms.forEach((farm: Farm) => {
        farm.UnitID = id;
      });
      this.farms = farms;
      if (this.shapeFarmNames.length) {
        this.onSelectFarm();
      }
      if (this.isSelectedOrganizationSatellite) {
        this.reloadParcels();
      }
      await this.$store.dispatch('hideGlobalLoader');
    }
    if (this.selectedUploadType === UploadType.CAR) {
      await this.$store.dispatch('showGlobalLoader', 'Loading car shapes...');
      const response = await API.getCarShapes(id);
      this.carShapes = response ? response.Shape : null;
      await this.$store.dispatch('hideGlobalLoader');
    }
  }

  public get duplicatedCarShapesInDB(): Record<string, number> {
    let duplicates: Record<string, number> = null;
    if (this.carShapes && this.shape) {
      const existValues = this.carShapes.features
        .map(
          (feature: Feature) =>
            feature.properties[this.carShapePropertyName] || feature.properties[this.carShapePropertyName.toLowerCase()]
        )
        .filter((value) => !!value);
      this.shape.features.forEach((feature: Feature) => {
        const value =
          feature.properties[this.carShapePropertyName] || feature.properties[this.carShapePropertyName.toLowerCase()];
        if (existValues.includes(value)) {
          duplicates = duplicates || {};
          duplicates[value] = (duplicates[value] || 0) + 1;
        }
      });
    }
    return duplicates;
  }

  public get duplicatedCarShapesInFile(): Record<string, number> {
    let duplicates: Record<string, number> = null;
    const shape = this.shape || featureCollection([]);
    const existValues: string[] = [];
    shape.features.forEach((feature: Feature) => {
      const value =
        feature.properties[this.carShapePropertyName] || feature.properties[this.carShapePropertyName.toLowerCase()];
      if (value) {
        if (existValues.includes(value)) {
          duplicates = duplicates || {};
          duplicates[value] = (duplicates[value] || 0) + 1;
        }
        existValues.push(value);
      }
    });
    return duplicates;
  }

  handleSeasonChange(season: string): void {
    this.selectedSeason = season;
  }

  getParcelDbName(item: ParcelMapping): string {
    if (item.overlapMapping.length > 0) {
      return item.overlapMapping.map((o: OverlapMapping) => `${o.dbName} (${o.percent}%)`).join(', ');
    }
    return item.dbName;
  }

  onParsePlantingDate(): void {
    this.parsedPropertyDate = this.parsePlantingDate(this.properties);
  }

  onParseCreationDate(): void {
    this.parsedPropertyCreationDate = this.parseCreationDate(this.properties);
  }

  onParseDeletionDate(): void {
    this.parsedPropertyDeletionDate = this.parseDeletionDate(this.properties);
  }
  onParseHarvestingDate(): void {
    this.parsedPropertyHarvestingDate = this.parseHarvestingDate(this.properties);
  }
  onParseNextHarvestDate(): void {
    this.parsedPropertyNextHarvestDate = this.parseNextHarvestDate(this.properties);
  }

  onSelectFarm(): void {
    const shapeFarmNames: FarmMapping[] = [];
    const values: string[] = [];
    if (this.shape) {
      this.shape.features.forEach((feature: Feature) => {
        const farmName = this.manualFarmField || feature.properties[this.selectedFarmField] || '';
        const farmCode = feature.properties[this.selectedFarmCodeField] || '';
        if (!values.includes(farmName + '|' + farmCode)) {
          const farm = this.farms
            ? this.farms.find(
                (farm: Farm) =>
                  (farm.Name || '').toLowerCase() === `${farmName}`.toLowerCase() && (farm.Code || '') === `${farmCode}`
              )
            : null;
          shapeFarmNames.push({
            featureName: farmName,
            featureCode: farmCode,
            dbId: farm ? farm.id : null,
            dbName: farm ? farm.Name : null,
            dbCode: farm ? farm.Code : null,
            validated: !!this.selectedSeason
          });
          values.push(farmName + '|' + farmCode);
        }
      });
    }
    this.shapeFarmNames = shapeFarmNames;
    if (!this.isSelectedOrganizationSatellite) {
      this.reloadParcels();
    }
  }

  private reloadParcels(): void {
    this.$store.dispatch('showGlobalLoader', 'Loading parcels...');
    const parcelTasks: Array<Promise<Parcel[]>> = [];
    if (this.isSelectedOrganizationSatellite) {
      this.farms.forEach((farm: Farm) => {
        parcelTasks.push(API.getParcels(farm.id));
      });
    } else {
      this.shapeFarmNames.forEach((shapeFarmName: FarmMapping) => {
        if (shapeFarmName.dbId) {
          parcelTasks.push(isIdFake(shapeFarmName.dbId) ? Promise.resolve([]) : API.getParcels(shapeFarmName.dbId));
        }
      });
    }
    Promise.all(parcelTasks)
      .then((result: Array<Parcel[]>) => {
        this.parcels = result.filter((parcel: Parcel[]) => !!parcel).flat();
        if (this.shapeParcelNames.length) {
          this.onSelectParcel();
        }
      })
      .finally(() => {
        this.$store.dispatch('hideGlobalLoader');
      });
  }

  private findParcel(feature: Feature, parcelName: string, parcelId: string): Parcel {
    if (this.shapeEditingResult && this.shapeEditingResult.mapping.length) {
      const mapping = this.shapeEditingResult.mapping.find(({ feature }) => {
        return parcelName === this.getParcelName(feature);
      });
      if (mapping) {
        return mapping.parcel;
      }
    }
    if (this.selectedSeason) {
      const cutStage = this.getCutStageFromFeature(feature);
      if (cutStage !== null) {
        const prevCutStage = cutStage - 1;
        const checkParcel = (parcel: Parcel) => {
          if (parcel.Seasons) {
            const cutStages = Object.values(parcel.Seasons).map((data: SeasonData) => data.CutStage);
            if (cutStages.includes(prevCutStage)) {
              const intersection = polygonClipping.intersection(
                (feature.geometry as any).coordinates,
                (parcel.Shape.features[0].geometry as any).coordinates
              );
              return intersection && intersection.length > 0;
            }
          }
          return false;
        };

        const parcelById = this.parcels.find((parcel: Parcel) => parcel.ParcelKey === parcelId && checkParcel(parcel));
        return parcelById || this.parcels.find((parcel: Parcel) => parcel.Name === parcelName && checkParcel(parcel));
      }
    }
    return null;
  }

  private getCutStageFromFeature(feature: Feature): number {
    return getCutStageFromFeature(feature, this.selectedCutStageField, this.cutStageMapping);
  }

  private getAreaM2FromFeature(feature: Feature): number {
    let areaInHa = feature.properties[this.selectedParcelAreaField];
    if (typeof areaInHa === 'string') {
      areaInHa = areaInHa ? parseInt(areaInHa) : null;
    }
    return areaInHa !== null && areaInHa !== undefined ? areaInHa * 10000 : null;
  }

  private getParcelIdFromFeature(feature: Feature): string {
    return feature.properties[this.selectedParcelIdField] ? `${feature.properties[this.selectedParcelIdField]}` : null;
  }

  private getParcelNameFromFeature(feature: Feature): string {
    return feature.properties[this.selectedParcelNameField]
      ? `${feature.properties[this.selectedParcelNameField]}`
      : null;
  }

  private onSelectParcel(): void {
    if (!this.selectedParcelIdField) {
      return;
    }
    const validatedFarmNames = this.shapeFarmNames
      .filter((farmMapping: FarmMapping) => farmMapping.validated)
      .map((farmMapping: FarmMapping) => farmMapping.featureName);
    const shapeParcelNames: ParcelMapping[] = [];
    this.shapeParcelNamesArea = {};
    if (this.shape) {
      this.shape.features.forEach((feature: Feature) => {
        const parcelId = this.getParcelIdFromFeature(feature) || '';
        const parcelName = this.getParcelNameFromFeature(feature) || '';
        const name = parcelName || parcelId;
        this.shapeParcelNamesArea[name] = this.shapeParcelNamesArea[name] || [];
        this.shapeParcelNamesArea[name].push(area(feature).toFixed());
      });

      const momentMaxOldPlantingDate = moment.utc().subtract(this.maxOldPlantingDateYears, 'years');
      this.shape.features.forEach((feature: Feature) => {
        const farmName = this.manualFarmField || feature.properties[this.selectedFarmField] || '';
        if (validatedFarmNames.includes(farmName)) {
          const parcelId = this.getParcelIdFromFeature(feature) || '';
          const farmCode = feature.properties[this.selectedFarmCodeField] || '';
          const parcelName = this.getParcelName(feature);
          const parcel = this.findParcel(feature, parcelName, parcelId);
          const plantingDate = this.parsePlantingDate(feature.properties);
          const creationDate = this.parseCreationDate(feature.properties);
          const lastHarvestDate = this.parseHarvestingDate(feature.properties);
          const isOldPlantingDate = moment.utc(plantingDate, this.plantingDateMask).isBefore(momentMaxOldPlantingDate);

          const item: ParcelMapping = {
            featureId: parcelId,
            featureName: parcelName,
            featureArea: this.getAreaM2FromFeature(feature),
            dbId: parcel ? parcel.id : null,
            dbName: parcel ? parcel.Name : null,
            featureFarmName: farmName,
            featureFarmCode: farmCode,
            zone: '' + (feature.properties[this.selectedZoneField] ?? ''),
            featureShape: feature.geometry as Polygon,
            plantingDate,
            creationDate,
            lastHarvestDate,
            harvestDates: parcel && parcel.HarvestDates && parcel.HarvestDates.length ? parcel.HarvestDates : [],
            variety: '' + (feature.properties[this.selectedVarietyField] ?? ''),
            environment: '' + (feature.properties[this.selectedProdEnvField] ?? ''),
            cutStage: this.getCutStageFromFeature(feature),
            rotation: feature.properties[this.selectedRotationField]
              ? JSON.stringify(feature.properties[this.selectedRotationField])
              : '',
            area: area(feature).toFixed(),
            overlapMapping: [],
            isOldPlantingDate,
            skipUpload: isOldPlantingDate,
            skipUploadManually: false,
            customFields: {}
          };

          this.customFieldsMapping.forEach((field: CustomFieldMapping) => {
            item.customFields[field.parcelField] = '' + (feature.properties[field.shapeField] ?? '');
          });

          shapeParcelNames.push(item);
        }
      });
    }
    this.shapeParcelNames = shapeParcelNames;
  }

  getMatchingStatistics(shapes: Mapping[]): string {
    return `${shapes.filter(({ dbId }) => !!dbId).length}/${shapes.length}`;
  }

  private getParcelName(feature: Feature): string {
    let parcelName = this.getParcelNameFromFeature(feature) || this.getParcelIdFromFeature(feature);
    if (!this.isSelectedOrganizationSatellite) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const allAreas = (this.shapeParcelNamesArea as any)[parcelName];
      if (allAreas && allAreas.length > 1) {
        const featureArea = area(feature).toFixed();
        const areasFeature = allAreas.filter((a: string) => a === featureArea);
        if (areasFeature.length === 1) {
          parcelName = `${parcelName}-${featureArea}`;
        } else {
          parcelName = `${parcelName}-${featureArea}-${this.shapeParcelUniqueNameCounter}`;
          this.shapeParcelUniqueNameCounter++;
        }
      }
    }
    return parcelName;
  }

  private updateShapeProperties(): void {
    if (this.shape) {
      this.shape.features.forEach((feature: Feature) => {
        feature.properties[this.propertiesEntityName] = this.selectedEntityId;
        feature.properties[this.propertiesDomainName] = this.selectedUnit.id;
        feature.properties[this.propertiesParcelName] = null;

        const farmField = this.manualFarmField || feature.properties[this.selectedFarmField] || '';
        const farmCode = feature.properties[this.selectedFarmCodeField] || '';
        const shapeFarm = this.shapeFarmNames.find(
          ({ featureName, featureCode }) => featureName === farmField && featureCode === farmCode
        );
        if (shapeFarm) {
          feature.properties[this.propertiesFarmName] = shapeFarm.dbId;
        }
        const parcelId = this.getParcelIdFromFeature(feature);
        let shapeParcel = this.shapeParcelNames.find(({ featureId }) => featureId === parcelId);
        if (!shapeParcel) {
          const parcelField = this.getParcelName(feature);
          shapeParcel = this.shapeParcelNames.find(({ featureName }) => featureName === parcelField);
        }
        if (
          shapeParcel &&
          !shapeParcel.skipUpload &&
          !shapeParcel.skipUploadManually &&
          !this.notUniqueParcelIds.includes(shapeParcel.featureId) &&
          !this.isParcelCutStage_0_1_andLastHarvestDate(shapeParcel) &&
          !this.isParcelCutStageMoreOneAndNoLastHarvestDate(shapeParcel) &&
          !this.isParcelCutStage_0_1_andNoPlantingDate(shapeParcel)
        ) {
          feature.properties[this.propertiesParcelName] = shapeParcel.dbId;
          feature.properties[this.propertiesAreaName] = shapeParcel.area;
          feature.properties[this.propertiesVarietyName] = shapeParcel.variety;
          feature.properties[this.propertiesZoneName] = shapeParcel.zone;
          feature.properties[this.propertiesEnvironmentName] = shapeParcel.environment;
          feature.properties[this.propertiesRotationName] = shapeParcel.rotation;
          feature.properties[this.propertiesCustomFieldsName] = shapeParcel.customFields;
        }
        feature.properties[this.propertiesCropTypeName] = this.selectedCropTypeId;
        feature.properties[this.propertiesPlantingDateName] = this.parsePlantingDate(feature.properties);
        feature.properties[this.propertiesCreationDateName] = this.parseCreationDate(feature.properties);
        feature.properties[this.propertiesDeletionDateName] = this.parseDeletionDate(feature.properties);
        feature.properties[this.propertiesHarvestingDateName] = this.parseHarvestingDate(feature.properties);
        feature.properties[this.propertiesNextHarvestDateName] = this.parseNextHarvestDate(feature.properties);
      });
    }
  }

  private isAllResolved(shapes: Mapping[]): boolean {
    return shapes.filter(({ dbId }) => !!dbId).length === shapes.length;
  }

  get isAllFarmsResolved(): boolean {
    return this.isAllResolved(this.shapeFarmNames);
  }

  get isCutStageMappingProvided(): boolean {
    return this.cutStageMapping ? Object.values(this.cutStageMapping).every((value) => value !== null) : false;
  }

  get isAllParcelsResolved(): boolean {
    return this.shapeParcelsToUpload.filter(({ dbId }) => !!dbId).length === this.shapeParcelsToUpload.length;
  }

  get parcelsWithIncorrectCutStage0_1LastHarvestFields(): ParcelMapping[] {
    return this.shapeParcelsToUpload.filter((mapping: ParcelMapping) =>
      this.isParcelCutStage_0_1_andLastHarvestDate(mapping)
    );
  }

  get parcelsWithIncorrectCutStage0_1PlantingDateFields(): ParcelMapping[] {
    return this.shapeParcelsToUpload.filter((mapping: ParcelMapping) =>
      this.isParcelCutStage_0_1_andNoPlantingDate(mapping)
    );
  }

  get parcelsWithIncorrectCutStage2LastHarvestFields(): ParcelMapping[] {
    return this.shapeParcelsToUpload.filter((mapping: ParcelMapping) =>
      this.isParcelCutStageMoreOneAndNoLastHarvestDate(mapping)
    );
  }

  private isParcelCutStage_0_1_andLastHarvestDate(mapping: ParcelMapping): boolean {
    return (mapping.cutStage === 0 || mapping.cutStage === 1) && !!mapping.lastHarvestDate;
  }

  private isParcelCutStage_0_1_andNoPlantingDate(mapping: ParcelMapping): boolean {
    return (mapping.cutStage === 0 || mapping.cutStage === 1) && !mapping.plantingDate;
  }

  private isParcelCutStageMoreOneAndNoLastHarvestDate(mapping: ParcelMapping): boolean {
    return mapping.cutStage > 1 && !mapping.lastHarvestDate;
  }

  get someParcelsHaveOldPlantingDate(): boolean {
    return this.shapeParcelNames.some(({ isOldPlantingDate }) => isOldPlantingDate);
  }

  get shapeParcelsToUpload(): ParcelMapping[] {
    return this.shapeParcelNames.filter(
      ({ skipUpload, featureId }) => !skipUpload && !this.notUniqueParcelIds.includes(featureId)
    );
  }

  get hasParcelsToUpload(): boolean {
    return this.shapeParcelsToUpload.length > 0;
  }

  get isParcelsContainOverlapping(): boolean {
    return this.shapeParcelNames.some((shapeParcel: ParcelMapping) => shapeParcel.overlapMapping.length > 0);
  }

  get isAreaInvalid(): boolean {
    return this.shapeParcelNames.some((shapeParcel: ParcelMapping) => {
      return parseFloat(shapeParcel.area) > this.MAX_SHAPE_AREA;
    });
  }

  get isSaveDisabled(): boolean {
    return (
      this.isAreaInvalid ||
      !this.isAllParcelsResolved ||
      !this.selectedParcelIdField ||
      this.isParcelsContainOverlapping
    );
  }

  get isUploadCarDisabled(): boolean {
    return !this.shape || !this.selectedUnit;
  }

  save(): void {
    this.updateShapeProperties();
    if (this.shape) {
      FileSaver.saveAs(new Blob([JSON.stringify(this.shape)]), this.getShapeName());
    }
  }

  get entitiesToUploadToServer(): any {
    return {
      edited: this.shapeEditingResult
        ? Object.values(this.shapeEditingResult.editedDbParcels).filter(({ feature }) => !feature.properties.invalid)
            .length
        : 0,
      deleted: this.shapeEditingResult ? Object.values(this.shapeEditingResult.deletedDbParcels).length : 0
    };
  }

  saveResultAndUpload(result: ShapeEditingResult): void {
    this.shapeEditingResult = result;
    this.uploadToServerDbChanges();
  }

  uploadToServerDbChanges(): void {
    const uploadRequest = {
      CountryID: this.selectedCountryId,
      CropID: this.selectedCropTypeId,
      OrganizationID: this.selectedEntityId,
      Units: []
    } as UploadRequest;

    this.addToUploadRequestDbChanges(uploadRequest);

    this.$store.dispatch('showGlobalLoader', 'Uploading...');
    this.doUpload(uploadRequest)
      .then(() => {
        message.success('Loading finished', 5);
        this.shapeEditingResult.editedDbParcels = {};
        this.shapeEditingResult.deletedDbParcels = {};
      })
      .catch(() => {
        message.error('Something went wrong', 5);
      })
      .finally(() => {
        this.$store.dispatch('hideGlobalLoader');
      });
  }

  saveRemaining(): void {
    this.updateShapeProperties();
    if (this.shape) {
      const approvedFarmIds = this.shapeFarmNames.filter(({ validated }) => !!validated).map(({ dbId }) => dbId);
      const features = this.shape.features.filter(
        (feature) =>
          !approvedFarmIds.includes(feature.properties[this.propertiesFarmName]) ||
          !feature.properties[this.propertiesParcelName]
      );
      FileSaver.saveAs(
        new Blob([JSON.stringify(featureCollection([...features, ...this.kinksFeatures]), null, 4)]),
        this.getShapeName(true)
      );
    }
  }

  private addToUploadRequest(
    parcel: Parcel,
    uploadRequest: UploadRequest,
    shapeFeature: Feature,
    parcelFarm?: Farm
  ): void {
    const farm = parcelFarm || this.farms.find((farm: Farm) => farm.id === parcel.FarmID);
    if (farm) {
      const unit = this.units.find((unit: Unit) => unit.id === farm.UnitID);
      if (unit) {
        let unitRequest = uploadRequest.Units.find((unitR) => unitR.id === unit.id);
        if (!unitRequest) {
          unitRequest = {
            id: unit.id,
            Name: unit.Name.toString(),
            Farms: []
          };
          uploadRequest.Units.push(unitRequest);
        }
        let farmRequest = unitRequest.Farms.find((farmR) => farmR.id === farm.id);
        if (!farmRequest) {
          farmRequest = {
            id: farm.id,
            Name: farm.Name.toString(),
            Code: (farm.Code || '').toString(),
            Parcels: []
          };
          unitRequest.Farms.push(farmRequest);
        }
        if (parcel.isDeleted) {
          farmRequest.Parcels.push({
            id: parcel.id,
            Deleted: parcel.Deleted,
            ParcelKey: parcel.ParcelKey ?? parcel.Name
          });
        } else {
          farmRequest.Parcels.push({
            id: parcel.id,
            ParcelKey: this.getParcelIdFromFeature(shapeFeature) ?? parcel.ParcelKey ?? parcel.Name,
            Area: this.getAreaM2FromFeature(shapeFeature) ?? area(shapeFeature),
            Name: parcel.Name.toString(),
            FarmName: farm.Name.toString(),
            FarmCode: (farm.Code || '').toString(),
            Created: shapeFeature.properties[this.propertiesCreationDateName]
              ? new Date(shapeFeature.properties[this.propertiesCreationDateName]).toISOString()
              : parcel.Created,
            Deleted: shapeFeature.properties[this.propertiesDeletionDateName]
              ? new Date(shapeFeature.properties[this.propertiesDeletionDateName]).toISOString()
              : parcel.Deleted,
            Planted: shapeFeature.properties[this.propertiesPlantingDateName]
              ? new Date(shapeFeature.properties[this.propertiesPlantingDateName]).toISOString()
              : parcel.Planted,
            Variety: shapeFeature.properties[this.propertiesVarietyName] ?? parcel.Variety,
            Zone: shapeFeature.properties[this.propertiesZoneName] ?? parcel.Zone,
            Environment: shapeFeature.properties[this.propertiesEnvironmentName] ?? parcel.Environment,
            CutStage: this.getCutStageFromFeature(shapeFeature) ?? parcel.CutStage,
            CustomFields:
              shapeFeature.properties[this.propertiesCustomFieldsName] &&
              Object.keys(shapeFeature.properties[this.propertiesCustomFieldsName]).length
                ? shapeFeature.properties[this.propertiesCustomFieldsName]
                : parcel.CustomFields,
            Rotation: shapeFeature.properties[this.propertiesRotationName] ?? parcel.Rotation,
            LastHarvestDate: shapeFeature.properties[this.propertiesHarvestingDateName] || null,
            NextHarvestDate: shapeFeature.properties[this.propertiesNextHarvestDateName]
              ? new Date(shapeFeature.properties[this.propertiesNextHarvestDateName]).toISOString()
              : null,
            Shape: featureCollection([feature(shapeFeature.geometry)])
          });
        }
      }
    }
  }

  private extendFarmsWithShape(): Promise<void> {
    const farmsById: { [key: string]: Farm } = {};
    this.farms.forEach((farm: Farm) => {
      if (!isIdFake(farm.id) && (!farm.Shape || !farm.Shape.features.length)) {
        farmsById[farm.id] = farm;
      }
    });
    const ids = Object.keys(farmsById);
    if (ids.length > 0) {
      const farmRequests = ids.map((id) => API.getFarm(id));
      return Promise.all(farmRequests).then((farms) => {
        farms.forEach((farm: Farm) => {
          if (farm && farmsById[farm.id]) {
            farmsById[farm.id] = farm;
          }
        });
      });
    }
    return Promise.resolve();
  }

  private checkFarmOverlapping(): Promise<boolean> {
    return new Promise((resolve) => {
      this.extendFarmsWithShape().then(() => {
        const onMessage = (result: MessageEvent) => {
          if (result.data.progress) {
            this.$store.dispatch('setGlobalLoaderMessage', result.data.progress);
            return;
          }
          this.farmOverlapMapping = result.data;
          if (this.farmOverlapMapping.length) {
            this.isManageFarmOverlappingVisible = true;
          }
          resolve(!!this.farmOverlapMapping.length);
          farmOverlapWorker.removeEventListener('message', onMessage);
        };

        farmOverlapWorker.addEventListener('message', onMessage);
        farmOverlapWorker.postMessage({
          uploadRequest: this.uploadRequest,
          farms: this.farms
        });
      });
    });
  }

  private checkSourceParcelsOverlapping(): Promise<boolean> {
    if (this.isSelectedOrganizationSatellite) {
      return Promise.resolve(false);
    }

    return new Promise((resolve) => {
      const onMessage = (result: MessageEvent) => {
        if (result.data.progress) {
          this.$store.dispatch('setGlobalLoaderMessage', result.data.progress);
          return;
        }
        this.sourceParcelOverlapMapping = result.data;
        if (this.sourceParcelOverlapMapping.length) {
          this.isManageSourceParcelOverlappingVisible = true;
        }
        resolve(!!this.sourceParcelOverlapMapping.length);
        sourceParcelOverlapWorker.removeEventListener('message', onMessage);
      };
      sourceParcelOverlapWorker.addEventListener('message', onMessage);
      sourceParcelOverlapWorker.postMessage({
        uploadRequest: this.uploadRequest
      });
    });
  }

  public validateParcelsSeasons(): void {
    this.$store.dispatch('showGlobalLoader', 'Validating parcels...');
    this.updateShapeProperties();
    this.getValidatedParcelsSeasonsResult().then((res: SeasonValidationOutput) => {
      if (res) {
        FileSaver.saveAs(new Blob([JSON.stringify(res, null, 4)]), 'validations.json');
        message.success('File with validation warnings has been saved.', 5);
      } else {
        message.success('There are no validation warnings.', 5);
      }
      this.$store.dispatch('hideGlobalLoader');
    });
  }

  private getValidatedParcelsSeasonsResult(): Promise<SeasonValidationOutput> {
    return new Promise((resolve) => {
      const onMessage = (result: MessageEvent) => {
        const res = result.data as SeasonValidationOutput;
        if (
          res.inconsistentFarms.length ||
          res.inconsistentGeometries.length ||
          res.inconsistentCutStage.length ||
          res.inconsistentVarieties.length ||
          res.inconsistentLastHarvestDate.length ||
          res.lastHarvestDateNotInPreviousSeason.length ||
          res.inconsistentPlantingDate.length ||
          res.noCutStage.length ||
          res.incorrectCutStageWithLastHarvestDate.length ||
          res.lastHarvestDateCutStage1More15Months.length ||
          res.plantingDateCutStage0Or1More20Months.length ||
          res.incorrectExpectedHarvestDateCutStage0.length ||
          res.incorrectExpectedHarvestDateCutStage1.length
        ) {
          resolve(res);
        } else {
          resolve(null);
        }
        parcelsSeasonValidationWorker.removeEventListener('message', onMessage);
      };

      parcelsSeasonValidationWorker.addEventListener('message', onMessage);
      parcelsSeasonValidationWorker.postMessage({
        parcels: this.parcels,
        season: this.selectedSeason,
        features: this.shape.features,
        parcelIdField: this.selectedParcelIdField,
        parcelNameField: this.selectedParcelNameField,
        cutStageField: this.selectedCutStageField,
        cutStageMapping: this.cutStageMapping,
        varietyField: this.selectedVarietyField,
        propertiesPlantingDateName: this.propertiesPlantingDateName,
        propertiesHarvestingDateName: this.propertiesHarvestingDateName,
        propertiesNextHarvestDateName: this.propertiesNextHarvestDateName
      });
    });
  }

  addToUploadRequestDbChanges(uploadRequest: UploadRequest): void {
    if (this.shapeEditingResult) {
      Object.keys(this.shapeEditingResult.deletedDbParcels).forEach((id) => {
        const parcel = this.shapeEditingResult.deletedDbParcels[id].parcel;
        if (parcel) {
          parcel.isDeleted = true;
          parcel.Deleted = this.shapeEditingResult.deletedDbParcels[id].deletedDate;
          this.addToUploadRequest(parcel, uploadRequest, null, parcel.Farm);
        }
      });

      Object.keys(this.shapeEditingResult.editedDbParcels).forEach((id) => {
        const parcel = this.shapeEditingResult.editedDbParcels[id].parcel;
        if (parcel) {
          this.addToUploadRequest(
            parcel,
            uploadRequest,
            this.shapeEditingResult.editedDbParcels[id].feature,
            parcel.Farm
          );
        }
      });
    }
  }

  async uploadCar(): Promise<void> {
    await this.$store.dispatch('showGlobalLoader', 'Uploading CAR shapes...');
    try {
      await API.uploadCarShapes(this.selectedUnit.id, this.shape);
      message.success('Uploading finished', 5);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      message.error('Something went wrong', 5);
    } finally {
      await this.$store.dispatch('hideGlobalLoader');
    }
  }

  upload(): void {
    this.updateShapeProperties();

    const uploadRequest = {
      CountryID: this.selectedCountryId,
      CropID: this.selectedCropTypeId,
      OrganizationID: this.selectedEntityId,
      Units: []
    } as UploadRequest;

    this.shape.features.forEach((shapeFeature: Feature) => {
      if (shapeFeature.properties[this.propertiesParcelName]) {
        const parcel = this.parcels.find(
          (parcel: Parcel) => parcel.id === shapeFeature.properties[this.propertiesParcelName]
        );
        if (parcel) {
          this.addToUploadRequest(parcel, uploadRequest, shapeFeature);
        }
      }
    });
    this.addToUploadRequestDbChanges(uploadRequest);

    this.parcels
      .filter((parcel) => parcel.isDeleted)
      .forEach((parcel: Parcel) => {
        this.addToUploadRequest(parcel, uploadRequest, null);
      });

    this.uploadRequest = uploadRequest;

    this.$store.dispatch('showGlobalLoader', 'Checking farm overlapping...');
    this.checkFarmOverlapping().then((hasOverlaping: boolean) => {
      if (hasOverlaping) {
        this.$store.dispatch('hideGlobalLoader');
        return;
      }
      this.checkSourceOverlappingAndUpload();
    });
  }

  private doUpload(uploadRequest: UploadRequest): Promise<void> {
    uploadRequest.Units.forEach((unitR) => {
      if (isIdFake(unitR.id)) {
        unitR.id = '';
      }
      unitR.Farms.forEach((farmR) => {
        if (isIdFake(farmR.id)) {
          farmR.id = '';
        }
        farmR.Parcels.forEach((parcelR) => {
          if (isIdFake(parcelR.id)) {
            parcelR.id = '';
          }
        });
      });
    });
    return API.upload(uploadRequest, this.isSelectedOrganizationSatellite ? this.selectedSeason : '');
  }

  private checkSourceOverlappingAndUpload(): void {
    this.$store.dispatch('showGlobalLoader', 'Checking source parcels overlapping...');
    this.checkSourceParcelsOverlapping().then((hasSourceParcelOverlap) => {
      if (hasSourceParcelOverlap) {
        this.$store.dispatch('hideGlobalLoader');
        return;
      }
      this.validateSeasonRules().then((skipUploading) => {
        if (skipUploading) {
          return;
        }
        this.$store.dispatch('showGlobalLoader', 'Uploading...');
        this.doUpload(this.uploadRequest)
          .then(() => {
            message.success('Loading finished', 5);
            this.isSuccessfullyUploaded = true;
          })
          .catch(() => {
            message.error('Something went wrong', 5);
          })
          .finally(() => {
            this.uploadRequest = null;
            this.$store.dispatch('hideGlobalLoader');
          });
      });
    });
  }

  private validateSeasonRules(): Promise<boolean> {
    if (this.selectedSeason) {
      this.$store.dispatch('setGlobalLoaderMessage', 'Validating season rules...');
      return this.getValidatedParcelsSeasonsResult().then((res: SeasonValidationOutput) => {
        this.$store.dispatch('hideGlobalLoader');
        if (res) {
          this.seasonValidationResult = res;
          return new Promise((resolve) => {
            this.seasonValidationResultModalPromise = resolve;
          });
        }
        return false;
      });
    }
    return Promise.resolve(false);
  }

  public uploadOnlyValidSeasonValidation(): void {
    this.uploadRequest.Units.forEach((unit: UnitRequest) => {
      unit.Farms.forEach((farm: FarmRequest) => {
        farm.Parcels = farm.Parcels.filter(
          (parcel: ParcelRequest) => !this.seasonValidationResult.invalidParcelIds.includes(parcel.ParcelKey)
        );
      });
    });

    this.seasonValidationResult = null;
    this.seasonValidationResultModalPromise(false);
  }

  public uploadAllSeasonValidation(): void {
    this.seasonValidationResult = null;
    this.seasonValidationResultModalPromise(false);
  }

  public cancelSeasonValidation(): void {
    this.seasonValidationResult = null;
    this.seasonValidationResultModalPromise(true);
  }

  private getShapeName(isRemaining = false): string {
    const nameParts = this.shapeName.split('.');
    return `${nameParts[0]}_corrected${isRemaining ? '_remaining.geojson' : '.json'}`;
  }

  private mapEntity(featureName: string, shapesNames: Mapping[], entities: Item[], dbId: string): Mapping {
    const shapeName = shapesNames.find((shapeName: Mapping) => shapeName.featureName === featureName);
    if (shapeName) {
      const entity = entities.find(({ id }) => id === dbId);
      shapeName.dbId = entity ? entity.id : null;
      shapeName.dbName = entity ? entity.Name : null;
    }
    return shapeName;
  }

  private processCreatedFarms(values: Array<[string, Farm]>): void {
    values.forEach((value: [string, Farm]) => {
      const shapeName = this.shapeFarmNames.find(
        ({ featureName, featureCode }) => value[0] === featureName + '|' + featureCode
      );
      if (shapeName) {
        shapeName.dbId = value[1].id;
        shapeName.dbName = value[1].Name;
        shapeName.dbCode = value[1].Code;
      }
      this.farms = this.farms || [];
      this.farms.push(value[1]);
    });
    if (!this.isSelectedOrganizationSatellite) {
      this.reloadParcels();
    }
  }

  private createNewFarm(shapeName: string, name: string, code: string): [string, Farm] {
    return [
      shapeName,
      {
        Name: name,
        Code: code,
        UnitID: this.selectedUnit.id,
        id: getFakeId()
      }
    ];
  }

  private processCreatedParcels(values: Array<[string, Parcel]>): void {
    values.forEach((value: [string, Parcel]) => {
      const shapeById = this.isSelectedOrganizationSatellite
        ? this.shapeParcelNames.find(({ featureId }) => value[1].ParcelKey === featureId)
        : null;
      const shape = shapeById || this.shapeParcelNames.find(({ featureName }) => value[0] === featureName);
      if (shape) {
        shape.dbId = value[1].id;
        shape.dbName = value[1].Name;
      }
      this.parcels.push(value[1]);
    });
  }

  private createNewParcel(shapeName: string, name: string, farmId: string, mapping: ParcelMapping): [string, Parcel] {
    return [
      shapeName,
      {
        ParcelKey: mapping.featureId,
        Name: name,
        FarmID: farmId,
        FarmName: mapping.featureFarmName,
        FarmCode: mapping.featureFarmCode,
        Planted: new Date(mapping.plantingDate).toISOString(),
        Created: new Date(mapping.creationDate).toISOString(),
        id: getFakeId(),
        Variety: mapping.variety,
        Zone: mapping.zone,
        Environment: mapping.environment,
        CutStage: mapping.cutStage,
        Rotation: mapping.rotation,
        ShapeID: null,
        Shape: null,
        Seasons: {},
        Deleted: null,
        HarvestDates: mapping.harvestDates,
        CustomFields: mapping.customFields
      }
    ];
  }

  createAllFarms(): void {
    if (this.shapeFarmNames && this.shapeFarmNames.length) {
      const farms: Array<[string, Farm]> = [];
      const shapeFarmNames = this.shapeFarmNames.filter(({ dbId }) => !dbId);
      shapeFarmNames.forEach((shape: FarmMapping) => {
        farms.push(
          this.createNewFarm(shape.featureName + '|' + shape.featureCode, shape.featureName, shape.featureCode)
        );
      });
      this.processCreatedFarms(farms);
    }
  }

  createFarm(name: string, code: string): void {
    this.createFarmItemName = name;
    this.createFarmItemCode = code;
  }

  closeCreateFarm(): void {
    this.createFarmItemName = null;
    this.createFarmItemCode = null;
  }

  onCreateFarm(name: string, code: string): void {
    if (name) {
      const shapeFarm = this.shapeFarmNames.find((farmMapping: FarmMapping) => {
        const featureName =
          farmMapping.featureName !== null && farmMapping.featureName !== undefined
            ? farmMapping.featureName.toString()
            : '';
        return (
          featureName === this.createFarmItemName.toString() &&
          (farmMapping.featureCode ? farmMapping.featureCode.toString() === this.createFarmItemCode.toString() : true)
        );
      });
      if (shapeFarm) {
        this.processCreatedFarms([
          this.createNewFarm(this.createFarmItemName + '|' + this.createFarmItemCode, name, code)
        ]);
      }
    }
    this.closeCreateFarm();
  }

  mapFarm(name: string): void {
    this.mapFarmItemName = name;
  }

  closeMapFarm(): void {
    this.mapFarmItemName = null;
  }

  onMapFarm(id: string): void {
    this.mapEntity(this.mapFarmItemName, this.shapeFarmNames, this.farms, id);
    this.closeMapFarm();
    if (!this.isSelectedOrganizationSatellite) {
      this.reloadParcels();
    }
  }

  mapParcel(name: string): void {
    this.mapParcelItemName = name;
  }

  closeMapParcel(): void {
    this.mapParcelItemName = null;
  }

  onMapParcel(id: string): void {
    const entity = this.mapEntity(this.mapParcelItemName, this.shapeParcelNames, this.parcels, id);
    if (entity) {
      (entity as ParcelMapping).overlapMapping = [];
    }
    this.closeMapParcel();
  }

  createAllParcels(): void {
    if (this.shapeParcelNames && this.shapeParcelNames.length) {
      const parcels: Array<[string, Parcel]> = [];
      const shapeParcelNames = this.shapeParcelNames.filter(({ dbId }) => !dbId);
      shapeParcelNames.forEach((shape: ParcelMapping) => {
        const shapeFarm = this.shapeFarmNames.find(
          ({ featureName, featureCode }) =>
            featureName === shape.featureFarmName && featureCode == shape.featureFarmCode
        );
        if (shapeFarm) {
          parcels.push(this.createNewParcel(shape.featureName, shape.featureName, shapeFarm.dbId, shape));
        }
      });
      this.processCreatedParcels(parcels);
    }
  }

  createParcel(name: string): void {
    this.createParcelItemName = name;
  }

  closeCreateParcel(): void {
    this.createParcelItemName = null;
  }

  changeSkipUploadManually(item: ParcelMapping, isSkip: boolean): void {
    item.skipUploadManually = isSkip;
  }

  onCreateParcel(name: string): void {
    if (name) {
      const shapeParcel = this.shapeParcelNames.find(({ featureName }) => featureName === this.createParcelItemName);
      if (shapeParcel) {
        const shapeFarm = this.shapeFarmNames.find(
          ({ featureName, featureCode }) =>
            featureName === shapeParcel.featureFarmName && featureCode == shapeParcel.featureFarmCode
        );
        if (shapeFarm) {
          this.processCreatedParcels([
            this.createNewParcel(this.createParcelItemName, name, shapeFarm.dbId, shapeParcel)
          ]);
        }
      }
    }
    this.closeCreateParcel();
  }

  onAddNewParcel(shapeName: string, parcel: Parcel): void {
    this.processCreatedParcels([[shapeName, parcel]]);
  }

  manageOverlapping(): void {
    this.isManageOverlappingVisible = true;
  }

  onCloseManageOverlapping(): void {
    this.isManageOverlappingVisible = false;
  }

  onCloseFarmManageOverlappingAndProceed(): void {
    this.isManageFarmOverlappingVisible = false;
    this.checkSourceOverlappingAndUpload();
  }

  onCloseFarmManageOverlapping(): void {
    this.isManageFarmOverlappingVisible = false;
  }

  onCloseSourceParcelManageOverlapping(): void {
    this.isManageSourceParcelOverlappingVisible = false;
  }

  someFarmValidated(): boolean {
    return this.shapeFarmNames.some((farmName: FarmMapping) => farmName.validated);
  }

  get notUniqueParcelIds(): string[] {
    if (!this.isSelectedOrganizationSatellite) {
      return [];
    }
    const parcelIds = this.shapeParcelNames.map((shapeParcelName: ParcelMapping) => shapeParcelName.featureId);
    return Array.from(new Set(parcelIds.filter((e, i, a) => a.indexOf(e) !== i)));
  }

  onShapeEdited(result: ShapeEditingResult, shape: FeatureCollection): void {
    this.shapeEditingResult = result;
    this.shape = shape;
    this.isShapeEditingVisible = false;
    this.onSelectParcel();
  }
  onDatesModified(modifiedDates: { props: any; plantingDate: string }[]): void {
    this.modifiedDates = modifiedDates;
  }
  manageParcelsForFarms(): void {
    this.isShapeEditingVisible = true;
  }

  private clustering(input: FeatureCollection): Promise<FeatureCollection> {
    return new Promise((resolve) => {
      const onMessage = (result: MessageEvent) => {
        if (result.data.progress) {
          this.$store.dispatch('setGlobalLoaderMessage', result.data.progress);
          return;
        }
        resolve(result.data);
        clusteringWorker.removeEventListener('message', onMessage);
      };
      clusteringWorker.addEventListener('message', onMessage);
      clusteringWorker.postMessage({
        input: input
      });
    });
  }
}
